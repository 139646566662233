import React from 'react';

const PaymentInstructionsCard = ( ) => {
  return (
    <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg mb-6">
      <h3 className="text-xl font-semibold text-green-500 mb-2">Payment Accounts</h3>
      <p className="text-sm mb-4 text-yellow-500">
        To confirm your booking, please send your deposit to one of our mobile money accounts below:
      </p>
      <div className="bg-slate-900 p-4 rounded-lg mb-4">
        <p className="font-semibold">Airtel Money:</p>
        <p className="pl-4">0976262031 (& Support) </p>
        <p className="pl-4">0771093440</p>
      </div>
      <p className="text-sm">
        <strong className='text-yellow-500'>Instructions:</strong> Once you’ve made the payment, take a screenshot and upload it by pressing the <span className="font-semibold text-green-500">Confirm</span> button.
      </p>
    </div>
  );
};

export default PaymentInstructionsCard;
