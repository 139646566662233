import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DashboardLayout from './DashboardLayout';
import Customers from './Customers';
import ServiceProviders from './ServiceProviders';
import Services from './Services';
import Requests from './Requests';
import Bookings from './Bookings';
import Categories from './Categories';
import Locations from './Locations';
import ServiceDetails from './ServiceDetails';
import AdminRequestDetails from './AdminRequestDetails';
import AdminBookingDetails from './AdminBookingDetails';
import AdminAffiliateDashboard from './AffiliateDashboard';
import AffiliateDetails from './AffiliateDetails';

const AdminDashboard = () => {
  return (
    <Routes>
      {/* The parent route containing the side menu */}
      <Route path="/" element={<DashboardLayout />}>
        {/* Nested routes for the dashboard content */}
        <Route path="customers" element={<Customers />} />
        <Route path="service-providers" element={<ServiceProviders />} />
        <Route path="services" element={<Services />} />
        <Route path="services/:id" element={<ServiceDetails/>} /> {/* Details page */}
        {/* <Route path="/admin/services/:id" component={<ServiceDetails/>} /> Details page */}
        <Route path="requests" element={<Requests />} />
        <Route path="bookings" element={<Bookings />} />
````````<Route path="categories" element={<Categories />} />
````````<Route path="locations" element={<Locations />} />
````````<Route path="requests/:id" element={<AdminRequestDetails />} />
````````<Route path="affiliate-dashboard" element={<AdminAffiliateDashboard/>} />
        <Route path="affiliate-dashboard/:id" element={<AffiliateDetails />} />
        {/* <Route path="affiliate-dashboard/:id" element={<AffiliateDetails/>} /> Details page */}
{/* ````````<Route path="bookings/:id" element={<AdminBookingDetails  />} />
````````<Route path="bookings/:id" element={<AdminBookingDetails  />} /> */}
      </Route>
    </Routes>
  );
};

export default AdminDashboard;






