import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
} from "firebase/firestore";
import { db } from "../services/firebase.config";
import MainSpinner from "../components/MainSpinner";
import { useAuthData } from "../context/AuthContext";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import PaymentInstructionsCard from "../components/PaymentInstructionsCard";
import { toast, ToastContainer } from 'react-toastify';

const fetchUnconfirmedBookings = async (customerId) => {
  const bookingsCollection = collection(db, "AYS Lite Bookings");
  const q = query(
    bookingsCollection,
    where("isConfirmed", "==", false),
    where("customerId", "==", customerId)
  );
  const snapshot = await getDocs(q);
  return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

const CustomerRequests = () => {
  const { userData } = useAuthData();
  const customerId = userData?.id;
  const [selectedBooking, setSelectedBooking] = useState(null); // For showing proof of payment form
  const [showRescheduleForm, setShowRescheduleForm] = useState(false);
  const [rescheduleData, setRescheduleData] = useState({
    date: "",
    time: "",
    reason: "",
  });
  const [proofFile, setProofFile] = useState(null);
  const [paymentType, setPaymentType] = useState("deposit");
  const [transactionNumber, setTransactionNumber] = useState("");
  const [senderName, setSenderName] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("Airtel Money");
  const storage = getStorage();
  const [showCancelForm, setShowCancelForm] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [cancelReason, setCancelReason] = useState("");

  const {
    data: bookings,
    isLoading,
    error,
  } = useQuery(
    ["AYS Lite Bookings", customerId],
    () => fetchUnconfirmedBookings(customerId),
    {
      enabled: !!customerId, // Only run the query if customerId is available
    }
  );

  // Event Handlers
  const handleProofFileChange = (e) => {
    setProofFile(e.target.files[0]);
  };

  const handleConfirmPayment = (bookingId) => {
    setSelectedBooking(bookingId);
  };


  const handleSubmitProof = async (e, bookingId) => {
    e.preventDefault(); // Prevent form submission
    let proofUrl = null;
  
    try {
      if (proofFile) {
        const storageRef = ref(
          storage,
          `bookings/${bookingId}/payment-proof/${proofFile.name}`
        );
        const snapshot = await uploadBytes(storageRef, proofFile);
        proofUrl = await getDownloadURL(snapshot.ref);
      }
  
      const bookingRef = doc(db, "AYS Lite Bookings", bookingId);
      const updatedPayments = {
        paymentType,
        senderName: "",
        transactionNumber: "",
        paymentMethod,
        proofUrl,
        status: "Team Verifying Payment",
        createdAt: new Date(),
      };
  
      await updateDoc(bookingRef, {
        payments: updatedPayments,
        status:"Verifying, contact us for fast response",
        isDepositPaid: paymentType === "deposit",
      });
  
      setSelectedBooking(null);
      toast.success("Proof of payment submitted successfully!");
    } catch (error) {
      console.error("Error submitting proof of payment:", error);
      toast.error("Error submitting proof of payment. Please try again.");
    }
  };
  
  const handleReschedule = (bookingId) => {
    setShowRescheduleForm(bookingId);
  };

  const handleSubmitReschedule = async (e, bookingId) => {
    e.preventDefault(); // Prevent form submission
    try {
      const bookingRef = doc(db, "AYS Lite Bookings", bookingId);
      const rescheduleRequest = {
        suggestedDate: rescheduleData.date,
        suggestedTimes: [rescheduleData.time],
        reason: rescheduleData.reason,
        isCustomerRequest: true,
        createdAt: new Date(),
        isAccepted: false,
      };
  
      await updateDoc(bookingRef, {
        rescheduleRequests: rescheduleRequest,
      });
  
      setShowRescheduleForm(false);
      toast.success("Reschedule request submitted successfully!");
    } catch (error) {
      console.error("Error submitting reschedule request:", error);
      toast.error("Error submitting reschedule request.");
    }
  };

  const handleCancelRequest = async (e, bookingId) => {
    e.preventDefault(); // Prevent form submission
    try {
      const bookingRef = doc(db, "AYS Lite Bookings", bookingId);
      await updateDoc(bookingRef, {
        status: "cancelled",
        cancelReason: cancelReason || "No reason provided",
        cancelledAt: new Date(),
        cancelledBy: "customer",
      });
      setShowCancelForm(false);
      toast.success("Request cancelled successfully!");
    } catch (error) {
      console.error("Error cancelling request:", error);
      toast.error("Something went wrong.");
    }
  };
  
 
  if (isLoading) return <MainSpinner />;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="bg-gray-900 min-h-screen p-4 text-white">
      <ToastContainer/>
      <h2 className="text-3xl font-semibold mb-6">My Requests</h2>
     
      {showInstructions ==false ? (
          <button
          className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 mb-1 rounded"
          onClick={() => setShowInstructions(true)}
        >
          Payment Instructions
        </button>
        ):(<button
          className="bg-gray-600 hover:bg-gray-600 text-white px-4 py-2 mb-1 rounded"
          onClick={() => setShowInstructions(false)}
        >
          Hide Instructions
        </button>)}
        {showInstructions ==true ?  (<PaymentInstructionsCard/>):(<div></div>)}
      
      <ul>
        {bookings.length === 0 ? (
          <p>No unconfirmed requests</p>
        ) : (
          bookings.map((booking) => (
            <li key={booking.id} className="bg-gray-800 p-4 rounded mb-4">
              <p>
                <strong>Service:</strong> {booking.packageName}
              </p>
              <p>
                <strong>Date:</strong> {booking.date}
              </p>
              <p>
                <strong>Time:</strong> {booking.time}
              </p>
              <p>
                <strong>Status:</strong> {booking.status}
              </p>

              {/* Confirm Payment Button */}
              <button
                className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded"
                onClick={() => handleConfirmPayment(booking.id)}
              >
                Confirm
              </button>

              
              {/* Cancel button */}
              <button
                className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded ml-4"
                onClick={() => setShowCancelForm(booking.id)}
              >
                Cancel 
              </button>

              {/* Reschedule Button */}
              <button
                className="bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2 rounded ml-4"
                onClick={() => handleReschedule(booking.id)}
              >
                Reschedule
              </button>

              {/* Payment Proof Form */}
              {selectedBooking === booking.id && (
              <div className="mt-4">
                <h3 className="text-lg font-semibold mb-4">Provide Proof of Payment</h3>
                <form onSubmit={(e) => handleSubmitProof(e, booking.id)}>
                  <div className="mb-4">
                    <label className="block text-white mb-2">Payment Type</label>
                    <select
                      value={paymentType}
                      onChange={(e) => setPaymentType(e.target.value)}
                      className="w-full p-2 rounded bg-gray-800 text-white"
                    >
                      <option value="deposit">Deposit</option>
                      <option value="full payment">Full Payment</option>
                      <option value="balance">Balance</option>
                    </select>
                  </div>
                  <div className="mb-4">
                    <label className="block text-white mb-2">Payment Method</label>
                    <select
                      value={paymentMethod}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      className="w-full p-2 rounded bg-gray-800 text-white"
                    >
                      <option value="Airtel Money">Airtel Money</option>
                      <option value="MoMo">MoMo</option>
                      <option value="Bank Transfer">Bank Transfer</option>
                      <option value="GenoPay">GenoPay (Coming Soon)</option>
                    </select>
                  </div>
                  <input type="file" onChange={handleProofFileChange} className="mb-4 w-full" />
                  <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded">
                    Submit Proof
                  </button>
                </form>
              </div>
            )}

              {/* Reschedule Form */}
              {showRescheduleForm === booking.id && (
                <div className="mt-4">
                  <h3 className="text-lg font-semibold mb-4">
                    Request to Reschedule
                  </h3>
                  <form onSubmit={(e) => handleSubmitReschedule(booking.id)}>
                    <div className="mb-4">
                      <label className="block text-white mb-2">New Date</label>
                      <input
                        type="date"
                        value={rescheduleData.date}
                        onChange={(e) =>
                          setRescheduleData({
                            ...rescheduleData,
                            date: e.target.value,
                          })
                        }
                        required
                        className="w-full p-2 rounded bg-gray-800 text-white"
                      />
                    </div>

                    <div className="mb-4">
                      <label className="block text-white mb-2">New Time</label>
                      <input
                        type="time"
                        value={rescheduleData.time}
                        onChange={(e) =>
                          setRescheduleData({
                            ...rescheduleData,
                            time: e.target.value,
                          })
                        }
                        required
                        className="w-full p-2 rounded bg-gray-800 text-white"
                      />
                    </div>

                    <div className="mb-4">
                      <label className="block text-white mb-2">
                        Reason (Optional)
                      </label>
                      <textarea
                        value={rescheduleData.reason}
                        onChange={(e) =>
                          setRescheduleData({
                            ...rescheduleData,
                            reason: e.target.value,
                          })
                        }
                        className="w-full p-2 rounded bg-gray-800 text-white"
                        rows="4"
                      ></textarea>
                    </div>

                    <button
                      type="submit"
                      className="bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2 rounded"
                    >
                      Submit Reschedule Request
                    </button>
                    <button
                      type="button"
                      onClick={() => setShowRescheduleForm(false)} // Close the form
                      className="bg-gray-500 text-white px-4 py-2 ml-4 rounded"
                    >
                      Cancel
                    </button>
                  </form>
                </div>
              )}
              {/* Cancel Request Form */}
              {showCancelForm === booking.id && (
                <div className="mt-4">
                  <h3 className="text-lg font-semibold mb-4">Cancel Request</h3>
                  <form onSubmit={(e) => handleCancelRequest(e,booking.id)}>
                    <div className="mb-4">
                      <label className="block text-white mb-2">
                        Reason for Cancellation (Optional)
                      </label>
                      <textarea
                        value={cancelReason}
                        onChange={(e) => setCancelReason(e.target.value)}
                        className="w-full p-2 rounded bg-gray-800 text-white"
                        rows="4"
                      />
                    </div>
                    <button
                      type="submit"
                      className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded"
                    >
                      Cancel Request
                    </button>
                    <button
                      type="button"
                      onClick={() => setShowCancelForm(false)} // Close the form
                      className="bg-gray-500 text-white px-4 py-2 ml-4 rounded"
                    >
                      Cancel
                    </button>
                  </form>
                  <div className="mt-32"></div>
                </div>
              )}
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default CustomerRequests;

// import React from 'react';
// import { useQuery } from 'react-query';
// import { collection, getDocs, query, where } from 'firebase/firestore';
// import { db } from '../services/firebase.config';
// import MainSpinner from '../components/MainSpinner';
// import { useAuthData } from '../context/AuthContext';

// const fetchUnconfirmedBookings = async (customerId) => {
//   const bookingsCollection = collection(db, 'AYS Lite Bookings');
//   const q = query(
//     bookingsCollection,
//     where('isConfirmed', '==', false),
//     where('customerId', '==', customerId)
//   );
//   const snapshot = await getDocs(q);
//   return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
// };

// const CustomerRequests = () => {
//   const { userData } = useAuthData();
//   const customerId = userData?.id;

//   const { data: bookings, isLoading, error } = useQuery(
//     ['AYS Lite Bookings', customerId],
//     () => fetchUnconfirmedBookings(customerId),
//     {
//       enabled: !!customerId, // Only run the query if customerId is available
//     }
//   );

//   if (isLoading) return <MainSpinner />;
//   if (error) return <p>Error: {error.message}</p>;

//   return (
//     <div className="bg-gray-900 min-h-screen p-4 text-white">
//       <h2 className="text-3xl font-semibold mb-6">My Requests</h2>
//       <ul>
//         {bookings.length === 0 ? (
//           <p>No unconfirmed requests</p>
//         ) : (
//           bookings.map((booking) => (
//             <li key={booking.id} className="bg-gray-800 p-4 rounded mb-4">
//               <p><strong>Service:</strong> {booking.packageName}</p>
//               <p><strong>Date:</strong> {booking.date}</p>
//               <p><strong>Time:</strong> {booking.time}</p>
//               <p><strong>Status:</strong> {booking.status}</p>
//             </li>
//           ))
//         )}
//       </ul>
//     </div>
//   );
// };

// export default CustomerRequests;
