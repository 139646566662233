import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useAffiliateCrud } from '../../hooks/useAffiliateCrud';
import MainSpinner from '../../components/MainSpinner';
import { useCommissionTracker } from '../../hooks/useCommissionTracker';
import { usePromoCodeCrud } from '../../hooks/usePromoCodeCrud';

const AffiliateDetails = () => {
  const { id: affiliateId } = useParams();
  const { getAffiliateById } = useAffiliateCrud();
  const [affiliate, setAffiliate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [promoCodes, setPromoCodes] = useState([]);
  const { commissions, isLoading: loadingCommissions, error } = useCommissionTracker(affiliateId);
  const { getPromoCodesByAffiliate } = usePromoCodeCrud();

  useEffect(() => {
    const fetchAffiliateData = async () => {
      try {
        const fetchedAffiliate = await getAffiliateById(affiliateId);
        setAffiliate(fetchedAffiliate);

        // Fetch promo codes for the affiliate
        const affiliatePromoCodes = await getPromoCodesByAffiliate(affiliateId);
        setPromoCodes(affiliatePromoCodes);

      } catch (error) {
        console.error("Error fetching affiliate:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchAffiliateData();
  }, [affiliateId, getAffiliateById, getPromoCodesByAffiliate]);

  if (loading) return <MainSpinner />;
  if (!affiliate) return <p>Affiliate not found.</p>;

  const totalCommission = commissions.reduce((sum, item) => sum + item.amount, 0);

  return (
    <div className="p-6 bg-gray-900 text-white">
        <Link to={`/admin/affiliate-dashboard/`}>
        <button className="pill bg-blue-900 text-white my-4 px-4 py-2 rounded-full font-bold">
          Back
        </button>
      </Link>
      <h2 className="text-3xl font-bold mb-4">Affiliate Details</h2>

      {/* Affiliate Info */}
      <div className="bg-gray-800 p-4 rounded-lg mb-6">
        <h3 className="text-2xl font-semibold mb-2">{affiliate.name}</h3>
        <p><strong>Email:</strong> {affiliate.email}</p>
        <p><strong>Phone:</strong> {affiliate.phone}</p>
        <p><strong>Area:</strong> {affiliate.area}</p>
        <p><strong>City:</strong> {affiliate.city}</p>
        <p><strong>Status:</strong> {affiliate.status}</p>
      </div>

      {/* Promo Codes Section */}
      <div className="bg-gray-800 p-4 rounded-lg mb-6">
        <h3 className="text-2xl font-semibold mb-2">Promo Codes</h3>
        <table className="table-auto w-full">
          <thead>
            <tr className="text-left border-b border-gray-700">
              <th className="py-2">Code</th>
              <th className="py-2">Discount</th>
              <th className="py-2">Usage Limit</th>
              <th className="py-2">Used</th>
              <th className="py-2">Status</th>
            </tr>
          </thead>
          <tbody>
            {promoCodes.map((code) => (
              <tr key={code.id}>
                <td className="py-2">{code.codeName}</td>
                <td className="py-2">{code.discount}%</td>
                <td className="py-2">{code.usageLimit}</td>
                <td className="py-2">{code.usageCount}</td>
                <td className="py-2">{code.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Commissions Section */}
      <div className="bg-gray-800 p-4 rounded-lg mb-6">
        <h3 className="text-2xl font-semibold mb-2">Commissions</h3>
        <p><strong>Total Earned:</strong> K{totalCommission} ZMW</p>
        <table className="table-auto w-full mt-4">
          <thead>
            <tr className="text-left border-b border-gray-700">
              <th className="py-2">Booking ID</th>
              <th className="py-2">Date</th>
              <th className="py-2">Service</th>
              <th className="py-2">Commission</th>
            </tr>
          </thead>
          <tbody>
            {commissions.map((commission) => (
              <tr key={commission.bookingId}>
                <td className="py-2">{commission.bookingId}</td>
                <td className="py-2">{commission.date.toDate().toLocaleDateString()}</td>
                <td className="py-2">{commission.serviceName}</td>
                <td className="py-2">K{commission.amount} ZMW</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AffiliateDetails;















// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import { useAffiliateCrud } from '../../hooks/useAffiliateCrud';
// import MainSpinner from '../../components/MainSpinner';
// import { useCommissionTracker } from '../../hooks/useCommissionTracker';
// import { usePromoCodeCrud } from '../../hooks/usePromoCodeCrud';

// const AffiliateDetails = () => {
//   const { id: affiliateId } = useParams();
//   const { getAffiliateById } = useAffiliateCrud();
//   const [affiliate, setAffiliate] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [promoCodes, setPromoCodes] = useState([]);
//   const { commissions, isLoading, error } = useCommissionTracker(affiliateId);
//   const { getPromoCodesByAffiliate } = usePromoCodeCrud();
//   useEffect(() => {
//     const fetchAffiliate = async () => {
//       try {
//         const fetchedAffiliate = await getAffiliateById(affiliateId);
//         setAffiliate(fetchedAffiliate);
//               // Fetch promo codes for the affiliate
//       const promoCodes = await getPromoCodesByAffiliate(affiliateId);
//       setPromoCodes(promoCodes);

//       } catch (error) {
//         console.error("Error fetching affiliate:", error);
//       } finally {
//         setLoading(false);
//       }
//     };
//     fetchAffiliate();
    

//   }, [affiliateId, getAffiliateById,getPromoCodesByAffiliate]);

//   if (loading) return <MainSpinner />;
//   if (!affiliate) return <p>Affiliate not found.</p>;

 
//   // Calculate total commission earned
//   const totalCommission = commissions.reduce((sum, item) => sum + item.amount, 0);

//   return (
//     <div className="p-6 bg-gray-900 text-white">
//       <h2 className="text-3xl font-bold mb-4">Affiliate Details</h2>

//       {/* Affiliate Info */}
//       <div className="bg-gray-800 p-4 rounded-lg mb-6">
//         <h3 className="text-2xl font-semibold mb-2">{affiliate.name}</h3>
//         <p><strong>Email:</strong> {affiliate.email}</p>
//         <p><strong>Phone:</strong> {affiliate.phone}</p>
//         <p><strong>Area:</strong> {affiliate.area}</p>
//         <p><strong>City:</strong> {affiliate.city}</p>
//         <p><strong>Status:</strong> {affiliate.status}</p>
//       </div>

//       {/* Promo Codes Section */}
//       <div className="bg-gray-800 p-4 rounded-lg mb-6">
//         <h3 className="text-2xl font-semibold mb-2">Promo Codes</h3>
//         <table className="table-auto w-full">
//           <thead>
//             <tr className="text-left border-b border-gray-700">
//               <th className="py-2">Code</th>
//               <th className="py-2">Discount</th>
//               <th className="py-2">Usage Limit</th>
//               <th className="py-2">Used</th>
//               <th className="py-2">Status</th>
//             </tr>
//           </thead>
//           <tbody>
//             {promoCodes.map((code) => (
//               <tr key={code.id}>
//                 <td className="py-2">{code.codeName}</td>
//                 <td className="py-2">{code.discount}%</td>
//                 <td className="py-2">{code.usageLimit}</td>
//                 <td className="py-2">{code.usageCount}</td>
//                 <td className="py-2">{code.status}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>

//       {/* Commissions Section */}
//       <div className="bg-gray-800 p-4 rounded-lg mb-6">
//         <h3 className="text-2xl font-semibold mb-2">Commissions</h3>
//         <p><strong>Total Earned:</strong> K{totalCommission} ZMW</p>
//         <table className="table-auto w-full mt-4">
//           <thead>
//             <tr className="text-left border-b border-gray-700">
//               <th className="py-2">Booking ID</th>
//               <th className="py-2">Date</th>
//               <th className="py-2">Service</th>
//               <th className="py-2">Commission</th>
//             </tr>
//           </thead>
//           <tbody>
//             {commissions.map((commission) => (
//               <tr key={commission.bookingId}>
//                 <td className="py-2">{commission.bookingId}</td>
//                 <td className="py-2">{commission.date.toDate().toLocaleDateString()}</td>
//                 <td className="py-2">{commission.serviceName}</td>
//                 <td className="py-2">K{commission.amount} ZMW</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default AffiliateDetails;











