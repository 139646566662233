
export const sendAdminWhatsAppAlert = async (message) => {
  // Define phone numbers with their corresponding API keys
  const phoneNumbers = [
    { phone: "+260975376688", apiKey: "1026752" },
    { phone: "+260976262031", apiKey: "1121054" }
  ];

  // Loop through each number and send the message with its respective API key
  phoneNumbers.forEach(async ({ phone, apiKey }) => {
    const url = `https://api.callmebot.com/whatsapp.php?phone=${phone}&text=${encodeURIComponent(message)}&apikey=${apiKey}`;

    try {
      const response = await fetch(url);
      console.log(`Message sent to ${phone}:`, response.statusText);
    } catch (error) {
      console.error(`Error sending WhatsApp message to ${phone}:`, error);
    }
  });
};


// export const  sendAdminWhatsAppAlert = async (message) => {
//     const phoneNumbers = ["+260975376688", "+260976262031"];
//     const apiKey = "1026752"; 
//     const apiKeyII = "1121054"; 
  
//     phoneNumbers.forEach(async (phone) => {
//       const url = `https://api.callmebot.com/whatsapp.php?phone=${phone}&text=${encodeURIComponent(message)}&apikey=${apiKey}`;
  
//       try {
//         const response = await fetch(url);
//         console.log(`Message sent to ${phone}:`, response.statusText);
//       } catch (error) {
//         console.error("Error sending WhatsApp message:", error);
//       }
//     });
//   };
  