// src/pages/AffiliateDashboard.js
import React from 'react';
import { useAuthData } from '../context/AuthContext';
// import CommissionTracker from '../components/CommissionTracker';
import MainSpinner from '../components/MainSpinner';
import CommissionTracker from '../components/Affiliates/CommissionTracker';
import { usePromoCodeCrud } from '../hooks/usePromoCodeCrud';

const AffiliateDashboard = () => {
  const { userData } = useAuthData();
  const { promoCodes, isLoading, error } = usePromoCodeCrud();

  if (isLoading) return <MainSpinner />;
  if (error) return <p>Error loading promo codes: {error.message}</p>;

  const myPromoCodes = promoCodes?.filter(code => code.affiliateId === userData.id);

  return (
    <div className="p-4">
      <h2 className="text-3xl font-bold mb-4">Affiliate Dashboard</h2>
      <h3 className="text-xl font-semibold mb-2">My Promo Codes</h3>
      <ul>
        {myPromoCodes.map(code => (
          <li key={code.id} className="bg-gray-800 p-4 mb-4 rounded-lg shadow-lg">
            <p><strong>Promo Code:</strong> {code.codeName}</p>
            <p><strong>Discount:</strong> {code.discount}%</p>
            <p><strong>Usage Limit:</strong> {code.usageLimit}</p>
            <p><strong>Remaining Uses:</strong> {code.usageLimit - (code.usageCount || 0)}</p>
          </li>
        ))}
      </ul>
      <CommissionTracker affiliateId={userData.id} />
    </div>
  );
};

export default AffiliateDashboard;
