import React from 'react';
import { Link } from 'react-router-dom';

const ServiceCard = ({ service, categoryName }) => {
  const minPrice = service.packages.length > 0 
    ? Math.min(...service.packages.map(pkg => pkg.price)) 
    : 'N/A';
  const maxPrice = service.packages.length > 0 
    ? Math.max(...service.packages.map(pkg => pkg.price)) 
    : 'N/A';

  // Use a default image if no image URL is provided for the service
  const defaultImageUrl = "https://via.placeholder.com/150?text=Service+Image";
  const serviceImageUrl = service.image || defaultImageUrl;

  return (
    <Link
      to={`/services/${service.id}`}
      className="block p-4 bg-gray-800 text-white rounded-lg shadow-md hover:bg-gray-700 transition duration-200 ease-in-out transform hover:scale-105"
    >
      <div className="service-card bg-gray-800 p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
        <img
          src={serviceImageUrl}
          alt={service.serviceName}
          className="w-full h-40 object-cover rounded-md mb-4"
        />
        <h3 className="service-name text-xl font-semibold text-green-500">{service.serviceName}</h3>
        <span className="featured bg-purple-800 text-white text-xs px-2 py-1 rounded-md">{categoryName}</span>
        <p className="price-range text-orange-400">
          {minPrice !== 'N/A' ? `From ${minPrice} ZMW to ${maxPrice} ZMW` : "No packages available"}
        </p>
        {service.onPromo && (
          <div className="promo-badge bg-red-500 text-white text-xs px-2 py-1 rounded-md">
            {service.discountPercentage}% off!
          </div>
        )}
        {service.recommended && <span className="recommended bg-green-500 text-white mr-2 text-xs px-4 py-1 rounded-md">Recommended</span>}
        {service.featured && <span className="featured bg-blue-500 text-white text-xs px-2 py-1 rounded-md">Featured</span>}
        <p className="mt-2 text-gray-400">{service.description}</p>
      </div>
    </Link>
  );
};

export default ServiceCard;






















// import React from 'react';
// import { Link } from 'react-router-dom';

// const ServiceCard = ({ service, categoryName }) => {
//   const minPrice = service.packages.length > 0 
//     ? Math.min(...service.packages.map(pkg => pkg.price)) 
//     : 'N/A';
//   const maxPrice = service.packages.length > 0 
//     ? Math.max(...service.packages.map(pkg => pkg.price)) 
//     : 'N/A';

//   return (
//     <Link
//       to={`/services/${service.id}`}
//       className="block p-4 bg-gray-800 text-white rounded-lg shadow-md hover:bg-gray-700 transition duration-200 ease-in-out transform hover:scale-105"
//     >
//       <div className="service-card bg-gray-800 p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
//         <h3 className="service-name text-xl font-semibold text-green-500">{service.serviceName}</h3>
//         <span className="featured bg-purple-800 text-white text-xs px-2 py-1 rounded-md">{categoryName}</span>
//         <p className="price-range text-orange-400">
//           {minPrice !== 'N/A' ? `From ${minPrice} ZMW to ${maxPrice} ZMW` : "No packages available"}
//         </p>
//         {service.onPromo && (
//           <div className="promo-badge bg-red-500 text-white text-xs px-2 py-1 rounded-md">
//             {service.discountPercentage}% off!
//           </div>
//         )}
//         {service.recommended && <span className="recommended bg-green-500 text-white mr-2 text-xs px-4 py-1 rounded-md">Recommended</span>}
//         {service.featured && <span className="featured bg-blue-500 text-white text-xs px-2 py-1 rounded-md">Featured</span>}
//         <p className="mt-2 text-gray-400">{service.description}</p>
//       </div>
//     </Link>
//   );
// };
// export default ServiceCard;
