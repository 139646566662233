import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ServiceDetails from "../ServiceDetails";
import CustomerBookings from "../CustomerBookings";
import CustomerRequests from "../CustomerRequests";
import AccountSettings from "../AccountSettings";
import Home from '../Home';
import FrontendMainLayout from './FrontendMainLayout';
import BookNow from '../BookNow';
import AffiliateDashboard from '../AffiliateDashboard';
import { useAuthData } from '../../context/AuthContext';
import LoginForm from '../../components/Auth/LoginForm';

const FrontendRoutes = () => {
  const { userData } = useAuthData();

  if (!userData) {
    // return <Navigate to="/login" />;
  return <LoginForm/>;
  }

  return (
    <Routes>
      <Route path="/" element={<FrontendMainLayout />}>
        <Route index element={<Home />} /> {/* This handles "/" */}
        <Route path="home" element={<Home />} /> {/* Explicit route for "/home" */}
        <Route path="services/:id" element={<ServiceDetails />} />
        <Route path="my-bookings" element={<CustomerBookings />} />
        <Route path="requests" element={<CustomerRequests />} />
        <Route path="affiliates" element={<AffiliateDashboard />} />
        <Route path="account" element={<AccountSettings />} />
        <Route path="services/:id/book/:packageName" element={<BookNow />} /> {/* No leading "/" */}
      </Route>
    </Routes>
  );
};

export default FrontendRoutes;
// const FrontendRoutes = () => {
//   return (
//     <Routes>
//       <Route path="/" element={<FrontendMainLayout />}>
//         <Route index element={<Home />} />
//         <Route path="services/:id" element={<ServiceDetails />} />
//         <Route path="my-bookings" element={<CustomerBookings />} />
//         <Route path="requests" element={<CustomerRequests />} />
//         <Route path="account" element={<AccountSettings />} />
//         <Route path="/services/:id/book/:packageName" element={<BookNow />} />
//       </Route>
//     </Routes>
//   );
// };

// export default FrontendRoutes;
