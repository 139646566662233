import React, { useState, useEffect } from 'react';
import { useCategoryData } from '../../hooks/userCategoryData';

const CategoryForm = ({ categoryData, allCategories, onClose }) => {
  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const [hasSubscriptionPackage, setHasSubscriptionPackage] = useState(false);
  const [active, setActive] = useState(true);
  const [commissionBased, setCommissionBased] = useState(false);
  const [isSubcategory, setIsSubcategory] = useState(false);
  const [parentCategoryId, setParentCategoryId] = useState('');
  const { createCategory, updateCategory } = useCategoryData();  // Get the hooks here

  const handleSubmit = (e) => {
    e.preventDefault();

    const newCategory = {
      name,
      image,
      hasSubscriptionPackage,
      active,
      commissionBased,
      isSubcategory,
      parentCategoryId: isSubcategory ? parentCategoryId : null,
    };

    if (categoryData) {
      // If categoryData exists, update the existing category
      updateCategory({ id: categoryData.id, updatedCategory: newCategory });
    } else {
      // Otherwise, create a new category
      createCategory(newCategory);
    }

    // Clear the form and close it
    clearForm();
    onClose();
  };

  // Effect to populate the form if we are editing a category
  useEffect(() => {
    if (categoryData) {
      setName(categoryData.name || '');
      setImage(categoryData.image || '');
      setHasSubscriptionPackage(categoryData.hasSubscriptionPackage || false);
      setActive(categoryData.active || false);
      setCommissionBased(categoryData.commissionBased || false);
      setIsSubcategory(categoryData.isSubcategory || false);
      setParentCategoryId(categoryData.parentCategoryId || '');
    }
  }, [categoryData]);

  const clearForm = () => {
    setName('');
    setImage('');
    setHasSubscriptionPackage(false);
    setActive(true);
    setCommissionBased(false);
    setIsSubcategory(false);
    setParentCategoryId('');
  };

  // Filter out categories that are already subcategories (you can't assign a subcategory as a parent)
  const parentCategoryOptions = allCategories.filter(category => !category.isSubcategory);

  return (
    <form onSubmit={handleSubmit} className="bg-gray-800 p-6 rounded-lg shadow-md">
      <div className="mb-4">
        <label className="block text-gray-400 text-sm font-bold mb-2">Category Name:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          className="w-full px-3 py-2 border border-gray-600 rounded-lg bg-gray-700 text-white"
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-400 text-sm font-bold mb-2">Category Image URL:</label>
        <input
          type="text"
          value={image}
          onChange={(e) => setImage(e.target.value)}
          className="w-full px-3 py-2 border border-gray-600 rounded-lg bg-gray-700 text-white"
        />
      </div>

      <div className="mb-4 flex items-center">
        <input
          type="checkbox"
          checked={hasSubscriptionPackage}
          onChange={() => setHasSubscriptionPackage(!hasSubscriptionPackage)}
          className="mr-2"
        />
        <label className="block text-gray-400 text-sm">Has Subscription Package</label>
      </div>

      <div className="mb-4 flex items-center">
        <input
          type="checkbox"
          checked={active}
          onChange={() => setActive(!active)}
          className="mr-2"
        />
        <label className="block text-gray-400 text-sm">Active</label>
      </div>

      <div className="mb-4 flex items-center">
        <input
          type="checkbox"
          checked={commissionBased}
          onChange={() => setCommissionBased(!commissionBased)}
          className="mr-2"
        />
        <label className="block text-gray-400 text-sm">Commission Based</label>
      </div>

      <div className="mb-4 flex items-center">
        <input
          type="checkbox"
          checked={isSubcategory}
          onChange={() => setIsSubcategory(!isSubcategory)}
          className="mr-2"
        />
        <label className="block text-gray-400 text-sm">Is Subcategory</label>
      </div>

      {isSubcategory && (
        <div className="mb-4">
          <label className="block text-gray-400 text-sm font-bold mb-2">Parent Category:</label>
          <select
            value={parentCategoryId}
            onChange={(e) => setParentCategoryId(e.target.value)}
            className="w-full px-3 py-2 border border-gray-600 rounded-lg bg-gray-700 text-white"
          >
            <option value="">Select Parent Category</option>
            {parentCategoryOptions.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
      )}

      <div className="flex space-x-4 mt-6">
        <button
          type="submit"
          className="bg-orange-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-orange-600 transition duration-300"
        >
          {categoryData ? 'Update Category' : 'Create Category'}
        </button>

        <button
          type="button"
          className="bg-gray-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-gray-600 transition duration-300"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default CategoryForm;

































// import React, { useState, useEffect } from 'react';
// import { useCategoryData } from '../../hooks/userCategoryData';
// // import { useCategoryData } from '../../hooks/userCategoryData';

//   const CategoryForm = ({ onSubmit, categoryData, allCategories, onClose }) => {
//   const [name, setName] = useState('');
//   const [image, setImage] = useState('');
//   const [hasSubscriptionPackage, setHasSubscriptionPackage] = useState(false);
//   const [active, setActive] = useState(true);
//   const [commissionBased, setCommissionBased] = useState(false);
//   const [isSubcategory, setIsSubcategory] = useState(false);
//   const [parentCategoryId, setParentCategoryId] = useState('');
//   const { createCategory, updateCategory } = useCategoryData(); // Assuming this is the hook managing categories

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     const newCategory = {
//       name,
//       image,
//       hasSubscriptionPackage,
//       active,
//       commissionBased,
//       isSubcategory,
//       parentCategoryId: isSubcategory ? parentCategoryId : null, // Set parentCategoryId if subcategory
//     };

//     if (categoryData) {
//       // If categoryData exists, update the existing category
//       updateCategory({ id: categoryData.id, ...newCategory });
//     } else {
//       // Otherwise, create a new category
//       createCategory(newCategory);
//     }

//     // onClose(); // Optionally close the form modal after submission
//   };

//   // Effect to populate the form if we are editing a category
//   useEffect(() => {
//     if (categoryData) {
//       setName(categoryData.name || '');
//       setImage(categoryData.image || '');
//       setHasSubscriptionPackage(categoryData.hasSubscriptionPackage || false);
//       setActive(categoryData.active || false);
//       setCommissionBased(categoryData.commissionBased || false);
//       setIsSubcategory(categoryData.isSubcategory || false);
//       setParentCategoryId(categoryData.parentCategoryId || '');
//     }
//   }, [categoryData]);


// //     e.preventDefault();

// //     const newCategory = {
// //       name,
// //       image,
// //       hasSubscriptionPackage,
// //       active,
// //       commissionBased,
// //       isSubcategory,
// //       parentCategoryId: isSubcategory ? parentCategoryId : null, // Only set parentCategoryId if it's a subcategory
// //     };

// //     onSubmit(newCategory);
// //     clearForm(); // Clear the form after submission
// //   };

//   const clearForm = () => {
//     setName('');
//     setImage('');
//     setHasSubscriptionPackage(false);
//     setActive(true);
//     setCommissionBased(false);
//     setIsSubcategory(false);
//     setParentCategoryId('');
//   };

//   // Filter out categories that are already subcategories (you can't assign a subcategory as a parent)
//   const parentCategoryOptions = allCategories.filter(category => !category.isSubcategory);

//   return (
//     <form onSubmit={handleSubmit} className="bg-gray-800 p-6 rounded-lg shadow-md">
//       <div className="mb-4">
//         <label className="block text-gray-400 text-sm font-bold mb-2">Category Name:</label>
//         <input
//           type="text"
//           value={name}
//           onChange={(e) => setName(e.target.value)}
//           required
//           className="w-full px-3 py-2 border border-gray-600 rounded-lg bg-gray-700 text-white"
//         />
//       </div>

//       <div className="mb-4">
//         <label className="block text-gray-400 text-sm font-bold mb-2">Category Image URL:</label>
//         <input
//           type="text"
//           value={image}
//           onChange={(e) => setImage(e.target.value)}
//           className="w-full px-3 py-2 border border-gray-600 rounded-lg bg-gray-700 text-white"
//         />
//       </div>

//       <div className="mb-4 flex items-center">
//         <input
//           type="checkbox"
//           checked={hasSubscriptionPackage}
//           onChange={() => setHasSubscriptionPackage(!hasSubscriptionPackage)}
//           className="mr-2"
//         />
//         <label className="block text-gray-400 text-sm">Has Subscription Package</label>
//       </div>

//       <div className="mb-4 flex items-center">
//         <input
//           type="checkbox"
//           checked={active}
//           onChange={() => setActive(!active)}
//           className="mr-2"
//         />
//         <label className="block text-gray-400 text-sm">Active</label>
//       </div>

//       <div className="mb-4 flex items-center">
//         <input
//           type="checkbox"
//           checked={commissionBased}
//           onChange={() => setCommissionBased(!commissionBased)}
//           className="mr-2"
//         />
//         <label className="block text-gray-400 text-sm">Commission Based</label>
//       </div>

//       <div className="mb-4 flex items-center">
//         <input
//           type="checkbox"
//           checked={isSubcategory}
//           onChange={() => setIsSubcategory(!isSubcategory)}
//           className="mr-2"
//         />
//         <label className="block text-gray-400 text-sm">Is Subcategory</label>
//       </div>

//       {/* Show the dropdown only if the category is a subcategory */}
//       {isSubcategory && (
//         <div className="mb-4">
//           <label className="block text-gray-400 text-sm font-bold mb-2">Parent Category:</label>
//           <select
//             value={parentCategoryId}
//             onChange={(e) => setParentCategoryId(e.target.value)}
//             className="w-full px-3 py-2 border border-gray-600 rounded-lg bg-gray-700 text-white"
//           >
//             <option value="">Select Parent Category</option>
//             {parentCategoryOptions.map((category) => (
//               <option key={category.id} value={category.id}>
//                 {category.name}
//               </option>
//             ))}
//           </select>
//         </div>
//       )}
//   <div className="flex space-x-4 mt-6">
//           <button
//             type="submit"
//             className="bg-orange-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-orange-600 transition duration-300"
//           >
//             {categoryData ? 'Update Category' : 'Create Category'}
//           </button>
          
//           <button
//             type="button"
//             className="bg-gray-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-gray-600 transition duration-300"
//             onClick={onClose}
//           >
//             Cancel
//           </button>
//         </div>
//       {/* <button
//         type="submit"
//         className="w-full bg-orange-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-orange-600 transition duration-300"
//       >
//         {categoryData ? 'Update Category' : 'Create Category'}
//       </button> */}
//     </form>
//   );
// };

// export default CategoryForm;
