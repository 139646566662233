import { useQuery, useMutation, useQueryClient } from 'react-query';
import { collection, addDoc, getDocs, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../services/firebase.config';

const categoriesCollectionRef = collection(db, 'AYS Lite Categories');

export const useCategoryData = () => {
  const queryClient = useQueryClient();

  // Fetch Categories
  const { data: categories, isLoading, isError } = useQuery('AYS Lite Categories', async () => {
    const querySnapshot = await getDocs(categoriesCollectionRef);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  });

  // Create Category
  const createCategory = useMutation(async (newCategory) => {
    await addDoc(categoriesCollectionRef, newCategory);
  }, {
    onSuccess: () => queryClient.invalidateQueries('AYS Lite Categories'),
  });

  // Update Category
  const updateCategory = useMutation(async ({ id, updatedCategory }) => {
    const categoryDoc = doc(db, 'AYS Lite Categories', id);
    await updateDoc(categoryDoc, updatedCategory);
  }, {
    onSuccess: () => queryClient.invalidateQueries('AYS Lite Categories'),
  });

  // Delete Category
  const deleteCategory = useMutation(async (id) => {
    const categoryDoc = doc(db, 'AYS Lite Categories', id);
    await deleteDoc(categoryDoc);
  }, {
    onSuccess: () => queryClient.invalidateQueries('AYS Lite Categories'),
  });

  // Return all CRUD methods
  return {
    categories,
    isLoading,
    isError,
    createCategory: createCategory.mutate,  // .mutate must be called on mutations
    updateCategory: updateCategory.mutate,  // Same for update
    deleteCategory: deleteCategory.mutate
  };
};













// import { useQuery, useMutation, useQueryClient } from 'react-query';
// import { collection, addDoc, getDocs, updateDoc, doc, deleteDoc } from 'firebase/firestore';
// import { db } from '../services/firebase.config';

// const categoriesCollectionRef = collection(db, 'AYS Lite Categories');

// export const useCategoryData = () => {
//   const queryClient = useQueryClient();

//   // Fetch Categories
//   const { data: categories, isLoading, isError } = useQuery('AYS Lite Categories', async () => {
//     const querySnapshot = await getDocs(categoriesCollectionRef);
//     return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
//   }, {
//     staleTime: 5 * 60 * 1000, // Optional: Set stale time to avoid refetching too often
//     refetchOnWindowFocus: false, // Optional: Disable refetching on window focus
//   });

//   // Create Category
//   const createCategory = useMutation(async (newCategory) => {
//     await addDoc(categoriesCollectionRef, newCategory);
//   }, {
//     onSuccess: () => {
//       queryClient.invalidateQueries('AYS Lite Categories');
//     },
//     onError: (error) => {
//       console.error('Error creating category:', error);
//     },
//   });

//   // Update Category
//   const updateCategory = useMutation(async ({ id, updatedCategory }) => {
//     const categoryDoc = doc(db, 'AYS Lite Categories', id);
//     await updateDoc(categoryDoc, updatedCategory);
//   }, {
//     onSuccess: () => {
//       queryClient.invalidateQueries('AYS Lite Categories');
//     },
//     onError: (error) => {
//       console.error('Error updating category:', error);
//     },
//   });

//   // Delete Category
//   const deleteCategory = useMutation(async (id) => {
//     const categoryDoc = doc(db, 'AYS Lite Categories', id);
//     await deleteDoc(categoryDoc);
//   }, {
//     onSuccess: () => {
//       queryClient.invalidateQueries('AYS Lite Categories');
//     },
//     onError: (error) => {
//       console.error('Error deleting category:', error);
//     },
//   });

//   return { categories, isLoading, isError, createCategory, updateCategory, deleteCategory };
// };


















