// src/services/authService.js
// import { db } from './firebaseConfig';
import { doc, setDoc } from 'firebase/firestore';
import { db } from './firebase.config';

export const createUserInFirestore = async (uid, data) => {
 
  try {
    await setDoc(doc(db, 'AYS Lite Users', uid), data);
    console.log("Login successful:", data);
   
  } catch (error) {
    console.error('Error creating user in Firestore: ', error);
    throw error;
  }
};
