import React, { useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import LoadingSpinner from '../LoadingSpinner';
import { useUserCreation } from '../../hooks/useUserCreation';
import { useAreaData } from '../../hooks/useAreaData';
import { useCityData } from '../../hooks/useCityData';
import { useNavigate } from 'react-router-dom';

const SignUpForm = () => {
  const { signupMutation } = useAuth();
  const { mutate: createUserInFirestore } = useUserCreation();
  const [city, setCity] = useState('');
  const [area, setArea] = useState('');
  const [role, setRole] = useState('customer'); // Add role state
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [nrc, setNRC] = useState('');
  const [gender, setGender] = useState('');
  // Fetch available , cities, and areas
  const navigate = useNavigate();
  const { cities = [] } = useCityData();
  const { areas: availableAreas = [] } = useAreaData();
  const handleSubmit = async (e) => {
    e.preventDefault();

    signupMutation.mutate(
      { email, password },
      {
        onSuccess: async (userCredential) => {
          const uid = userCredential.user.uid;
          const userData = {
            email,
            role,
            nrc,
            name,
            city,
            gender,
            phone,
            area,
            verified:false,
            status: 'active'
          };
          createUserInFirestore({ uid, userData });
          
          navigate('/');  // Navigate to admin dashboard on successful login
        }
      }
    );
  };
    // Filter areas based on the selected city
    const filteredAreas = availableAreas.filter(area => area.cityId === city);
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4 text-center">AYS Lite Sign Up</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Name:
            </label>
            <input
              type="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Email:
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Phone:
            </label>
            <input
             
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Password:
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
             {/* Gender */}
             <div className="mb-4">
          <label className="block text-gray-400 text-sm font-bold mb-2">
            Gender:
          </label>
          <select
            value={gender}
            onChange={(e) => setGender(e.target.value)} // Set selected gender
            required
            className="w-full px-3 py-2 border border-gray-600 rounded-lg bg-gray-700 text-white"
          >
            <option value="">Select Gender</option>
           
              <option  value={"Male"}>
                Male
              </option>
              <option  value={"Female"}>
                Female
              </option>
            
          </select>
        </div>
          {/* City */}
           <div className="mb-4">
          <label className="block text-gray-400 text-sm font-bold mb-2">
            City:
          </label>
          <select
            value={city}
            onChange={(e) => setCity(e.target.value)} // Set selected city
            required
            className="w-full px-3 py-2 border border-gray-600 rounded-lg bg-gray-700 text-white"
          >
            <option value="">Select City</option>
            {cities.map((city) => (
              <option key={city.id} value={city.id}>
                {city.name}
              </option>
            ))}
          </select>
        </div>

        {/* Areas Multi-Select */}
        {city && filteredAreas.length > 0 && (
          <div className="mb-4">
            <label className="block text-gray-400 text-sm font-bold mb-2">
              Location:{"Optional"} 
            </label>
            <select
              
              value={area}
              onChange={(e) => setArea(e.target.value)}
              className="w-full px-3 py-2 border border-gray-600 rounded-lg bg-gray-700 text-white"
            >
              {filteredAreas.map((area) => (
                <option key={area.id} value={area.id}>
                  {area.name}
                </option>
              ))}
            </select>
          </div>
        )}
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              NRC:
            </label>
            <input
              type="text"
              value={nrc}
              onChange={(e) => setNRC(e.target.value)}
              required
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
           {/* Role Selection */}
           <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Role:
            </label>
            <select
              value={role}
              onChange={(e) => setRole(e.target.value)}
              required
              className="w-full px-3 py-2 border rounded-lg focus:outline-none text-black focus:ring-2 focus:ring-blue-500"
            >
              <option value="customer" className='text-black'>Customer</option>
              <option value="affiliate" className='text-black'>Affiliate</option>
            </select>
          </div>
          <button
            type="submit"
            disabled={signupMutation.isLoading}
            className="w-full bg-blue-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
          >
            {signupMutation.isLoading ? <LoadingSpinner /> : 'Sign Up'}
          </button>
          {signupMutation.error && (
            <p className="text-red-500 text-sm mt-4">
              Error: {signupMutation.error.message}
            </p>
          )}
        </form>
      </div>
    </div>
  );
};

export default SignUpForm;
