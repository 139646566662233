import { useQuery, useMutation, useQueryClient } from 'react-query';
import { db } from '../services/firebase.config';
import { collection, addDoc, getDocs, updateDoc, doc, deleteDoc } from 'firebase/firestore';

const areasCollectionRef = collection(db, 'Areas');

export const useAreaData = () => {
  const queryClient = useQueryClient();

  // Fetch Areas
  const { data: areas = [], isLoading, isError } = useQuery('Areas', async () => {
    const querySnapshot = await getDocs(areasCollectionRef);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  });

  // Create Area
  const createArea = useMutation(async (newArea) => {
    await addDoc(areasCollectionRef, newArea);
  }, {
    onSuccess: () => queryClient.invalidateQueries('Areas'),
  });

  // Update Area
  const updateArea = useMutation(async ({ id, updatedArea }) => {
    const areaDoc = doc(db, 'Areas', id);
    await updateDoc(areaDoc, updatedArea);
  }, {
    onSuccess: () => queryClient.invalidateQueries('Areas'),
  });

  // Delete Area
  const deleteArea = useMutation(async (id) => {
    const areaDoc = doc(db, 'Areas', id);
    await deleteDoc(areaDoc);
  }, {
    onSuccess: () => queryClient.invalidateQueries('Areas'),
  });

  return {
    areas,
    isLoading,
    isError,
    createArea: createArea.mutate,
    updateArea: updateArea.mutate,
    deleteArea: deleteArea.mutate,
  };
};
