
import React from 'react';
import { Link } from 'react-router-dom';
import { useCategoryData } from '../../hooks/userCategoryData';

const ServiceTableRow = ({ service, onEdit }) => {
  const { categories, isLoading: categoriesLoading } = useCategoryData();
  const minPrice = service.packages.length > 0 
  ? Math.min(...service.packages.map(pkg => pkg.price)) 
  : 'N/A';
const maxPrice = service.packages.length > 0 
  ? Math.max(...service.packages.map(pkg => pkg.price)) 
  : 'N/A';

  // Lookup the category name
  const category = !categoriesLoading
    ? categories.find((cat) => cat.id === service.category)
    : null;
    
  return (
    <tr>
      <td className="px-4 py-2">
        <Link to={`/admin/services/${service.id}`} className="text-orange-400 hover:underline">
          {service.serviceName}
        </Link>
      </td>
      <td className="px-4 py-2">
        {/* Display the category name if found, otherwise show 'Unknown' or a placeholder */}
        {category ? category.name : "Unknown"}
      </td>
       <td className="px-4 py-2 bold">
       <p className="text-orange-400">
          {minPrice !== 'N/A' ? `${minPrice} ZMW - ${maxPrice} ZMW` : "No packages available"}
        </p>
       </td>
      <td className="px-4 py-2">
        <button 
          onClick={() => onEdit(service)}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md"
        >
          Edit
        </button>
      </td>
    </tr>
  );
};

export default ServiceTableRow;













// import React from 'react';
// import { Link } from 'react-router-dom';
// import { useCategoryData } from "../../";
// const ServiceTableRow = ({ service, onEdit }) => {
//   const { categories, isLoading: categoriesLoading } = useCategoryData();
//   return (
//     <tr>
//       <td className="px-4 py-2">
//         <Link to={`/admin/services/${service.id}`} className="text-orange-400 hover:underline">
//         {/* <Link to={`/admin/services/${service.id}`} className="text-orange-400 hover:underline"> */} 
//           {service.serviceName}
//         </Link>
//       </td>
//       <td className="px-4 py-2"> {
//           const category = categories.find(
//             (cat) => cat.id === service.category
//           );
//           category.name
          
//         }</td>
//       {/* <td className="px-4 py-2">{service.category}</td> */}
//       <td className="px-4 py-2">{`${service.priceRangeStart} - ${service.priceRangeEnd}`}</td>
//       <td className="px-4 py-2">
//              <td className="px-4 py-2">
//          <button 
//           onClick={() => onEdit(service)} // Call the onEdit function with the selected service
//           className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md"
//         >
//           Edit
//         </button>
//       </td>
//       </td>
//     </tr>
//   );
// };

// export default ServiceTableRow;






