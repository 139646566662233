import React from "react";
import { Link, useParams } from "react-router-dom";
import { useServiceData } from "../hooks/useServices";
import MainSpinner from "../components/MainSpinner";
import logo from '../assets/AYDLOGO-Lite.png';


// const PackageCard = ({ pkg, serviceId }) => (
//   <div className="bg-gray-800 p-4 rounded mb-4">
//     <h3 className="text-xl font-semibold mb-2 text-green-500">{pkg.name}</h3>
//     <span className="featured bg-purple-800 text-white font-bold px-1 py-1 rounded-md">
//       Price: K{pkg.price}
//     </span>
//     <h5 className="text-lg font-semibold text-orange-500">Description</h5>
//     <p>{pkg.description}</p>

//     {/* Deposit Information */}
//     <div className="mt-2">
//       <p className="text-yellow-400">
//         Deposit: {pkg.depositType === 'amount' ? `K${pkg.depositValue}` : `${pkg.depositValue}%`}
//       </p>
//     </div>

//     <Link to={`/services/${serviceId}/book/${encodeURIComponent(pkg.name)}`}>
//     {/* <Link to={`/services/${serviceId}/book/${pkg.name}`}> */}
//       <button className="pill bg-green-500 text-white my-4 px-4 py-2 rounded-full font-bold">
//         Book Now
//       </button>
//     </Link>
//   </div>
// );

const PackageCard = ({ pkg, serviceId, serviceImageUrl }) => {
  const packageImageUrl = pkg.imageUrl || serviceImageUrl || logo;

  return (
    <div className="bg-gray-800 p-4 rounded mb-4">
      {/* Package Image */}
      <img
        src={packageImageUrl}
        alt={pkg.name}
        className="w-full h-40 object-cover rounded-md mb-4"
      />

      <h3 className="text-xl font-semibold mb-2 text-green-500">{pkg.name}</h3>
      <span className="featured bg-purple-800 text-white font-bold px-1 py-1 rounded-md">
        Price: K{pkg.price}
      </span>
      <h5 className="text-lg font-semibold text-orange-500">Description</h5>
      <p>{pkg.description}</p>

      {/* Deposit Information */}
      <div className="mt-2">
        <p className="text-yellow-400">
          Deposit: {pkg.depositType === "amount" ? `K${pkg.depositValue}` : `${pkg.depositValue}%`}
        </p>
      </div>

      <Link to={`/services/${serviceId}/book/${encodeURIComponent(pkg.name)}`}>
        <button className="pill bg-green-500 text-white my-4 px-4 py-2 rounded-full font-bold">
          Book Now
        </button>
      </Link>
    </div>
  );
};


const ServiceDetails = () => {
 
  const { id } = useParams();
  const { data: services, isLoading } = useServiceData();
  
  const service = services?.find((service) => service.id === id);

  if (isLoading) return <MainSpinner />;
  if (!service) return <p>Service not found</p>;
  const minPrice = service.packages.length > 0 
  ? Math.min(...service.packages.map(pkg => pkg.price)) 
  : 'N/A';
const maxPrice = service.packages.length > 0 
  ? Math.max(...service.packages.map(pkg => pkg.price)) 
  : 'N/A';

  return (
    <div className="service-details bg-gray-900 min-h-screen p-4 text-white">
      <Link to={"/"}>
          <button className="pill bg-blue-900 text-white my-4 px-4 py-2 rounded-full font-bold">
            Back
           </button>
      </Link>
      <h2 className="text-3xl font-semibold text-yellow-400 mb-4">{service.serviceName}</h2>
      <p className="price-range text-orange-400 text-lg">
          {minPrice !== 'N/A' ? `From ${minPrice} ZMW to ${maxPrice} ZMW` : "No packages available"}
        </p>
      <p className="text-lg mb-6">{service.description}</p>

      {service.packages.map((pkg) => (
        <PackageCard key={pkg.name} pkg={pkg} serviceId={service.id} serviceImageUrl={service.image} />
      ))}
        {/* bottom padding */}
        <div className="mt-20"></div> 
    </div>
  );
};

export default ServiceDetails;
