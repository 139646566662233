import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../../services/firebase.config";
import MainSpinner from "../../components/MainSpinner";
import { useAuthData } from "../../context/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useServiceProviderCrud } from "../../hooks/useServiceProdiverCrud";



const fetchUnconfirmedBookings = async () => {
  const bookingsCollection = collection(db, "AYS Lite Bookings");
  const q = query(bookingsCollection, where("isConfirmed", "==", false));
  const snapshot = await getDocs(q);

  const bookings = await Promise.all(
    snapshot.docs.map(async (bookingDoc) => {
      const bookingData = bookingDoc.data();

      // Fetch customer data based on customerId
      const customerRef = doc(db, "AYS Lite Users", bookingData.customerId);
      const customerSnap = await getDoc(customerRef);
      const customerData = customerSnap.exists() ? customerSnap.data() : {};

      return {
        ...bookingData,
        id: bookingDoc.id, // Use bookingDoc.id to get the document ID
        customerName: customerData.name || "Unknown",
        customerArea: customerData.area || "Unknown",
        customerPhone: customerData.phone || "N/A",
      };
    })
  );

  return bookings;
};



const Requests = () => {
  const {
    serviceProviders = [],
    isLoading: isLoadingServiceProviders,
    isError,
  } = useServiceProviderCrud();
  const [selectedBooking, setSelectedBooking] = useState(null); // For showing proof of payment form
  const [showRescheduleForm, setShowRescheduleForm] = useState(false);
  const [rescheduleData, setRescheduleData] = useState({
    date: "",
    time: "",
    reason: "",
  });
  const [selectedServiceProviders, setSelectedServiceProviders] = useState({}); // Object to hold ServiceProvider selections for each booking

  const { userData } = useAuthData();
  const [proofFile, setProofFile] = useState(null);
  const [paymentType, setPaymentType] = useState("deposit");
  const [transactionNumber, setTransactionNumber] = useState("");
  const [senderName, setSenderName] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("Airtel Money");
  const storage = getStorage();
  const [showCancelForm, setShowCancelForm] = useState(false);
  const [cancelReason, setCancelReason] = useState("");

  const {
    data: bookings,
    isLoading,
    error,
  } = useQuery(["AYS Lite Bookings"], () => fetchUnconfirmedBookings());

  // Event Handlers
  const handleProofFileChange = (e) => {
    setProofFile(e.target.files[0]);
  };

  const handleConfirmPayment = (bookingId) => {
    setSelectedBooking(bookingId);
  };
  
  // Function to assign selected ServiceProvider to booking
  const handleAssignServiceProvider = async (bookingId) => {
    const selectedServiceProvider = selectedServiceProviders[bookingId];

    // Check if selectedServiceProvider exists
    if (!selectedServiceProvider || !selectedServiceProvider.id) {
      console.error("No ServiceProvider selected or ServiceProvider ID is missing");
      toast.error("Please select an ServiceProvider before confirming assignment.");
      return;
    }

    try {
      // Ensure bookingId is valid and selectedServiceProvider contains required data
      if (!bookingId) {
        console.error("Booking ID is undefined");
        return;
      }

      const bookingRef = doc(db, "AYS Lite Bookings", bookingId);
      await updateDoc(bookingRef, {
        assignedServiceProviderId: selectedServiceProvider.id,
        assignedServiceProviderName: selectedServiceProvider.name,
      });
      console.log(`Assigned ServiceProvider ${selectedServiceProvider.name} to booking.`);
      
      toast.success(`Assigned ServiceProvider ${selectedServiceProvider.name} to booking.`);
    } catch (error) {
      console.error("Error assigning ServiceProvider:", error);
      toast.error("Failed to assign ServiceProvider.");
    }
  };



  // Confirm Payment
  const handleSubmitProof = async (bookingId) => {
    let proofUrl = null;
  
    try {
      if (proofFile) {
        const storageRef = ref(storage, `bookings/${bookingId}/payment-proof/${proofFile.name}`);
        const snapshot = await uploadBytes(storageRef, proofFile);
        proofUrl = await getDownloadURL(snapshot.ref);
      }
  
      const bookingRef = doc(db, "AYS Lite Bookings", bookingId);
      const updatedPayments = {
        paymentType,
        senderName,
        transactionNumber,
        paymentMethod,
        proofUrl,
        createdAt: new Date(),
      };
  
      // Determine fields to update based on payment type
      const updateFields = {
        payments: [...(selectedBooking.payments || []), updatedPayments], // Track all payments
        confirmedBy: userData.id,
      };
  
      // Update payment status based on payment type
      if (paymentType === "deposit") {
        updateFields.isDepositPaid = true;
        updateFields.status = "Deposit Paid";
      } else if (paymentType === "full payment") {
        updateFields.isFullPaymentPaid = true;
        updateFields.status = "Full Payment Completed";
      } else if (paymentType === "balance") {
        updateFields.isFullPaymentPaid = true;
        updateFields.status = "Balance Paid";
      }
  
      // If the full payment is completed, mark the booking as confirmed
      if (updateFields.isDepositPaid && updateFields.isFullPaymentPaid) {
        updateFields.isConfirmed = true;
        updateFields.status = "Confirmed";
        updateFields.confirmedAt = new Date();
      }
  
      await updateDoc(bookingRef, updateFields);
  
      setSelectedBooking(null); // Hide form after submission
      // Add a success message or toast here if desired
    } catch (error) {
      console.error("Error submitting proof of payment:", error);
    }
  };
  
  
  
  

  const handleReschedule = (bookingId) => {
    setShowRescheduleForm(bookingId);
  };

  const handleSubmitReschedule = async (bookingId) => {
    try {
      const bookingRef = doc(db, "AYS Lite Bookings", bookingId);
      const rescheduleRequest = {
        suggestedDate: rescheduleData.date,
        suggestedTimes: [rescheduleData.time],
        reason: rescheduleData.reason,
        isCustomerRequest: false, // Admin initiated reschedule
        createdAt: new Date(),
        isAccepted: false, // Admin accepts later
      };

      await updateDoc(bookingRef, {
        rescheduleRequests: [
          ...selectedBooking.rescheduleRequests,
          rescheduleRequest,
        ],
      });

      setShowRescheduleForm(false);
    } catch (error) {
      console.error("Error submitting reschedule request:", error);
    }
  };

  const handleCancelRequest = async (bookingId) => {
    try {
      const bookingRef = doc(db, "AYS Lite Bookings", bookingId);
      await updateDoc(bookingRef, {
        status: "cancelled",
        cancelReason: cancelReason || "No reason provided",
        cancelledBy: "admin", // Mark who canceled
        cancelledAt: new Date(), // Time of cancellation
      });
      setShowCancelForm(false); // Close form
    } catch (error) {
      console.error("Error cancelling request:", error);
    }
  };

  if (isLoading) return <MainSpinner />;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="bg-gray-900 min-h-screen p-4 text-white">
      <ToastContainer /> {/* Toast notifications */}
      <h2 className="text-3xl font-semibold mb-6">Requests</h2>
      <ul>
        {bookings.length === 0 ? (
          <p>No unconfirmed requests</p>
        ) : (
          bookings.map((booking) => (
            <li key={booking.id} className="bg-gray-800 p-4 rounded mb-4">
              <p>
                <strong>Service:</strong> {booking.packageName} (ID: {booking.id})
              </p>
              <p>
                <strong>Date:</strong> {booking.date}
              </p>
              <p>
                <strong>Time:</strong> {booking.time}
              </p>
              <p>
                <strong>Status:</strong> {booking.status}
              </p>
              <p>
                <strong>Customer Name:</strong> {booking.customerName}
              </p>
              <p>
                <strong>Customer Area:</strong> {booking.customerArea}
              </p>
              <p>
                <strong>Phone Number:</strong> {booking.customerPhone}
                {/* Display assigned ServiceProvider if available */}
                <p>
                  <strong>Assigned Provider:</strong>{" "}
                  {booking.assignedServiceProviderName || "Not assigned"}
                </p>
              </p>
              {/* ServiceProvider Assignment */}
              <div className="mb-4">
                <label className="block text-white mb-2">
                  Assign Service Provider
                </label>
                <select
                  value={selectedServiceProviders[booking.id]?.id || ""}
                  onChange={(e) => {
                    const selectedSProvider = serviceProviders.find(
                      (emp) => emp.id === e.target.value
                    );
                    if (selectedSProvider) {
                      setSelectedServiceProviders((prev) => ({
                        ...prev,
                        [booking.id]: selectedSProvider,
                      }));
                    }
                  }}
                  className="w-full p-2 rounded bg-gray-800 text-white"
                >
                  <option value="">Select Service Provider</option>
                  {serviceProviders.map((emp) => (
                    <option key={emp.id} value={emp.id}>
                      {emp.name}
                    </option>
                  ))}
                </select>
                <button
                  className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded mt-2"
                  onClick={() => {
                    if (booking.id) {
                      handleAssignServiceProvider(booking.id);
                    } else {
                      console.error("Booking ID is undefined.");
                    }
                  }}
                >
                  Confirm Assignment
                </button>
              </div>

              {/* Confirm Payment Button */}
              <button
                className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded"
                onClick={() => handleConfirmPayment(booking.id)}
              >
                Confirm
              </button>

              {/* Cancel button */}
              <button
                className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded ml-4"
                onClick={() => setShowCancelForm(booking.id)}
              >
                Cancel
              </button>

              {/* Reschedule Button */}
              <button
                className="bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2 rounded ml-4"
                onClick={() => handleReschedule(booking.id)}
              >
                Reschedule
              </button>

              {/* Payment Proof Form */}
              {selectedBooking === booking.id && (
                <div className="mt-4">
                  <h3 className="text-lg font-semibold mb-4">
                    Confirm Booking
                  </h3>
                  <form onSubmit={(e) => { 
                            e.preventDefault(); // Prevent page reload 
                            handleSubmitProof(booking.id); 
                          }}>
                    <div className="mb-4">
                      <label className="block text-white mb-2">
                        Payment Type
                      </label>
                      <select
                        value={paymentType}
                        onChange={(e) => setPaymentType(e.target.value)}
                        className="w-full p-2 rounded bg-gray-800 text-white"
                      >
                        <option value="deposit">Deposit</option>
                        <option value="full payment">Full Payment</option>
                        <option value="balance">Balance</option>
                      </select>
                    </div>

                    <div className="mb-4">
                      <label className="block text-white mb-2">
                        Sender Name
                      </label>
                      <input
                        type="text"
                        value={senderName}
                        onChange={(e) => setSenderName(e.target.value)}
                        required
                        className="w-full p-2 rounded bg-gray-800 text-white"
                      />
                    </div>

                    <div className="mb-4">
                      <label className="block text-white mb-2">
                        Payment Method
                      </label>
                      <select
                        value={paymentMethod}
                        onChange={(e) => setPaymentMethod(e.target.value)}
                        className="w-full p-2 rounded bg-gray-800 text-white"
                      >
                        <option value="Airtel Money">Airtel Money</option>
                        <option value="MoMo">MoMo</option>
                        <option value="Bank Transfer">Bank Transfer</option>
                        <option value="GenoPay">GenoPay (Coming Soon)</option>
                      </select>
                    </div>

                    <div className="mb-4">
                      <label className="block text-white mb-2">
                        Upload Proof of Payment (Optional)
                      </label>
                      <input
                        type="file"
                        onChange={handleProofFileChange}
                        className="w-full p-2 rounded bg-gray-800 text-white"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-white mb-2">
                        Transaction Number (Optional)
                      </label>
                      <input
                        type="text"
                        value={transactionNumber}
                        onChange={(e) => setTransactionNumber(e.target.value)}
                        className="w-full p-2 rounded bg-gray-800 text-white"
                      />
                    </div>
                    <button
                      type="submit"
                      className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded"
                    >
                      Confirm Booking
                    </button>
                    <button
                      type="button"
                      onClick={() => setSelectedBooking(null)} // Close the form
                      className="bg-gray-500 text-white px-4 py-2 ml-4 rounded"
                    >
                      Cancel
                    </button>
                  </form>
                </div>
              )}

              {/* Reschedule Form */}
              {showRescheduleForm === booking.id && (
                <div className="mt-4">
                  <h3 className="text-lg font-semibold mb-4">
                    Request to Reschedule
                  </h3>
                  <form onSubmit={() => handleSubmitReschedule(booking.id)}>
                    <div className="mb-4">
                      <label className="block text-white mb-2">New Date</label>
                      <input
                        type="date"
                        value={rescheduleData.date}
                        onChange={(e) =>
                          setRescheduleData({
                            ...rescheduleData,
                            date: e.target.value,
                          })
                        }
                        required
                        className="w-full p-2 rounded bg-gray-800 text-white"
                      />
                    </div>

                    <div className="mb-4">
                      <label className="block text-white mb-2">New Time</label>
                      <input
                        type="time"
                        value={rescheduleData.time}
                        onChange={(e) =>
                          setRescheduleData({
                            ...rescheduleData,
                            time: e.target.value,
                          })
                        }
                        required
                        className="w-full p-2 rounded bg-gray-800 text-white"
                      />
                    </div>

                    <div className="mb-4">
                      <label className="block text-white mb-2">
                        Reason (Optional)
                      </label>
                      <textarea
                        value={rescheduleData.reason}
                        onChange={(e) =>
                          setRescheduleData({
                            ...rescheduleData,
                            reason: e.target.value,
                          })
                        }
                        className="w-full p-2 rounded bg-gray-800 text-white"
                        rows="4"
                      ></textarea>
                    </div>

                    <button
                      type="submit"
                      className="bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2 rounded"
                    >
                      Submit Reschedule Request
                    </button>
                    <button
                      type="button"
                      onClick={() => setShowRescheduleForm(false)} // Close the form
                      className="bg-gray-500 text-white px-4 py-2 ml-4 rounded"
                    >
                      Cancel
                    </button>
                  </form>
                </div>
              )}
              {/* Cancel Request Form */}
              {showCancelForm === booking.id && (
                <div className="mt-4">
                  <h3 className="text-lg font-semibold mb-4">Cancel Request</h3>
                  <form onSubmit={() => handleCancelRequest(booking.id)}>
                    <div className="mb-4">
                      <label className="block text-white mb-2">
                        Reason for Cancellation (Optional)
                      </label>
                      <textarea
                        value={cancelReason}
                        onChange={(e) => setCancelReason(e.target.value)}
                        className="w-full p-2 rounded bg-gray-800 text-white"
                        rows="4"
                      />
                    </div>
                    <button
                      type="submit"
                      className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded"
                    >
                      Cancel Request
                    </button>
                    <button
                      type="button"
                      onClick={() => setShowCancelForm(false)} // Close the form
                      className="bg-gray-500 text-white px-4 py-2 ml-4 rounded"
                    >
                      Cancel
                    </button>
                  </form>
                  <div className="mt-32"></div>
                </div>
              )}
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default Requests;
