import emailjs from "emailjs-com";

export const sendEmailNotification = async (bookingData,customer_name,customer_phone) => {
  const serviceID = "service_hgmnwcr";  // Replace with your EmailJS Service ID
  const templateID = "template_2dc1ead";  // Replace with your EmailJS Template ID
  const userID = "7Vo2N5tttyFx3r6UG";  // Replace with your EmailJS User ID

  const emailParams = {
    to_email1: "chipekow@gmail.com", // First recipient
    to_email2: "ayszambia@gmail.com", // Second recipient
    to_name: "Admin", // Who the email is addressed to
    customer_name: customer_name,
    customer_phone: customer_phone,
    service_name: bookingData.serviceName,
    package_name: bookingData.packageName,
    booking_date: bookingData.date,
    booking_time: bookingData.time,
    full_payment: bookingData.fullPaymentAmount,
    deposit_amount: bookingData.depositAmount,
    customer_note: bookingData.customerNote || "No additional notes.",
  };

  try {
    await emailjs.send(serviceID, templateID, emailParams, userID);
    console.log("Email sent successfully!");
  } catch (error) {
    console.error("Error sending email:", error);
  }
};
