import React, { useState } from 'react';
import ServiceForm from '../../components/Services/ServiceForm';
import ServiceList from '../../components/Services/ServiceList';


const Services = () => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [currentService, setCurrentService] = useState(null);

  const handleServiceSubmit = (serviceData) => {
    console.log("Form submitted with data:", serviceData);
    // Add logic to save or update the service data in Firestore or another database
  };

  const handleCreate = () => {
    setCurrentService(null);  // Reset form for new service
    setIsFormVisible(true);
  };

  const handleEdit = (service) => {
    setCurrentService(service);
    setIsFormVisible(true);
  };

  return (
    <div>
      <h2 className="text-3xl font-bold mb-4">Services</h2>
      <button 
        className="bg-blue-500 text-white px-4 py-2 rounded-md mb-4"
        onClick={handleCreate}
      >
        Create Service
      </button>
      
      {isFormVisible && (
        <ServiceForm
        onSubmit={handleServiceSubmit}
          serviceData={currentService} 
          onClose={() => setIsFormVisible(false)} 
        />
      )}
      
      <ServiceList onEdit={handleEdit} />
    </div>
  );
};

export default Services;
