import { useMutation, useQueryClient } from 'react-query';
import { collection, addDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../services/firebase.config';

// Add Booking
const addBooking = async (bookingData) => {
  const bookingsCollection = collection(db, 'AYS Lite Bookings');
  await addDoc(bookingsCollection, bookingData);
};

// Update Booking (e.g., for confirming deposit, etc.)
const updateBooking = async (id, updatedData) => {
  const bookingDocRef = doc(db, 'AYS Lite Bookings', id);
  await updateDoc(bookingDocRef, updatedData);
};

export const useBookingData = () => {
  const queryClient = useQueryClient();

  const createBooking = useMutation(addBooking, {
    onSuccess: () => {
      queryClient.invalidateQueries('AYS Lite Bookings');
    },
  });

  const confirmBooking = useMutation(updateBooking, {
    onSuccess: () => {
      queryClient.invalidateQueries('AYS Lite Bookings');
    },
  });

  return {
    createBooking: createBooking.mutate,
    confirmBooking: confirmBooking.mutate,
    
  };
};
