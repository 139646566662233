import React, { useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import LoadingSpinner from '../LoadingSpinner';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../assets/AYDLOGO-Lite.png';

const LoginForm = () => {
  const { loginMutation } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    loginMutation.mutate(
      { email, password },
      {
        onSuccess: () => {
          toast.success('Login successful!');
          navigate('/'); // Navigate to the home page on successful login
        },
        onError: (error) => {
          toast.error(`Login failed: ${error.message}`);
        }
      }
    );
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 px-4">
      <ToastContainer />
      <div className="bg-gray-800 p-8 rounded-lg shadow-md w-full max-w-md text-white">
        
        {/* Logo Section */}
        <div className="flex flex-col items-center mb-6">
          <img src={logo} alt="AYS Lite Logo" className="h-24 mb-2" />
          <h1 className="text-2xl font-bold text-yellow-400">AYS Lite</h1>
          <p className="text-gray-300 text-sm">Book a service today!</p>
        </div>

        {/* Login Form */}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-300 text-sm font-semibold mb-2">
              Email
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full px-4 py-2 border rounded-lg bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-300 text-sm font-semibold mb-2">
              Password
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full px-4 py-2 border rounded-lg bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
            />
          </div>

          {/* Login Button */}
          <button
            type="submit"
            disabled={loginMutation.isLoading}
            className="w-full bg-yellow-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-yellow-600 transition duration-300 mb-4"
          >
            {loginMutation.isLoading ? <LoadingSpinner /> : 'Login'}
          </button>

          {/* Signup Link */}
          <Link to="/signup">
            <button className="w-full bg-green-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-green-600 transition duration-300">
              Signup
            </button>
          </Link>

          {/* Error Message */}
          {loginMutation.error && (
            <p className="text-red-400 text-sm mt-4 text-center">
              {loginMutation.error.message}
            </p>
          )}
        </form>
      </div>
    </div>
  );
};

export default LoginForm;











// import React, { useState } from 'react';
// import { useAuth } from '../../hooks/useAuth';
// import LoadingSpinner from '../LoadingSpinner';
// import { Link, useNavigate } from 'react-router-dom';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { logDOM } from '@testing-library/react';
// import logo from '../../assets/AYDLOGO-Lite.png';
// const LoginForm = () => {
//   const { loginMutation } = useAuth();
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const navigate = useNavigate();

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log("Attempting to log in with: ", { email });

//     loginMutation.mutate(
//       { email, password },
//       {
//         onSuccess: (user) => {
//           // Log user details for debugging purposes
//           console.log("Login successful:", user);
//           toast.success('Login successful!');
//           navigate('/');  // Navigate to admin dashboard on successful login
//         },
//         onError: (error) => {
//           console.error("Login error:", error);
//           toast.error(`Login failed: ${error.message}`);
//         }
//       }
//     );
//   };

//   return (
//     <div className="min-h-screen flex items-center justify-center bg-gray-900">
        
//       <ToastContainer /> {/* Toast notifications */}
//       <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md">
//       <div className="logo text-xl font-bold items-center justify-center text-yellow-400">
//           <img src={logo} alt="" className="h-20 inline  mr-2" />
//           Lite
//         </div>
//         <h2 className="text-2xl font-bold mb-4 text-center text-green-500">Book a service today!</h2>
//         <form onSubmit={handleSubmit}>
//           <div className="mb-4">
//             <label className="block text-gray-700 text-sm font-bold mb-2">
//               Email:
//             </label>
//             <input
//               type="email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               required
//               className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
//             />
//           </div>
//           <div className="mb-6">
//             <label className="block text-gray-700 text-sm font-bold mb-2">
//               Password:
//             </label>
//             <input
//               type="password"
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//               required
//               className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
//             />
//           </div>
//           <button
//             type="submit"
//             disabled={loginMutation.isLoading}
//             className="w-full bg-blue-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
//           >
//             {loginMutation.isLoading ? <LoadingSpinner /> : 'Login'}
//           </button>
//           <Link to="/signup">
//             <button className="w-full bg-green-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-green-600 transition duration-300">
//               Signup
//             </button>
//           </Link>
//         {/* <Link to="/signup">
//           <button
            
//             // disabled={loginMutation.isLoading}
//             className="w-full bg-green-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-green-600 transition duration-300"
//           >
//              Signup
//           </button>
//           </Link> */}
//           {loginMutation.error && (
//             <p className="text-red-500 text-sm mt-4">
//               Error: {loginMutation.error.message}
//             </p>
//           )}
//         </form>
//       </div>
//     </div>
//   );
// };

// export default LoginForm;
