import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ServiceCard from "../components/Services/ServiceCard";
import { useServiceData } from "../hooks/useServices";
import { useCategoryData } from "../hooks/userCategoryData";
import MainSpinner from "../components/MainSpinner";
import { useAuthData } from "../context/AuthContext";
import { signOut } from "firebase/auth";
import { auth } from "../services/firebase.config";
import { useMediaQuery } from "react-responsive";
import LoginForm from "../components/Auth/LoginForm";
import logo from '../assets/AYDLOGO-Lite.png';

const Home = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const { userData, loading: loadingUser } = useAuthData();
  const {
    data: services,
    isLoading: servicesLoading,
    error,
  } = useServiceData();
  const { categories, isLoading: categoriesLoading } = useCategoryData();
  const [selectedCategory = [], setSelectedCategory] = useState(null);
  const [filteredServices = [], setFilteredServices] = useState([]);
  const navigate = useNavigate(); // For navigation on sign out

  // Sign out method
  const handleSignOut = async () => {
    try {
      await signOut(auth);
      // navigate("/login"); // Redirect to login page after sign out
       return <LoginForm/>;
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  // Filter services based on the selected category
  useEffect(() => {
    if (selectedCategory) {
      const filtered = services.filter(
        (service) => service.category === selectedCategory.id
      );
      setFilteredServices(filtered);
    } else {
      setFilteredServices(services); // Show all services if no category is selected
    }
  }, [selectedCategory, services]);

  

  if (servicesLoading || categoriesLoading || loadingUser || !userData) {
    return <MainSpinner />;
  }
  if (error) return <p>Something went wrong: {error.message}</p>;

  return (
    <div className="customer-home-container bg-gray-900 min-h-screen">
      {/* Header Bar */}
      <header className="flex items-center justify-between bg-gray-800 text-white p-4">
        {/* Logo */}
        <div className="logo text-xl font-bold text-yellow-400">
          <img src={logo} alt="" className="h-20 inline mr-2" />
          Lite
        </div>

        {/* User and Sign Out */}
        {!isMobile && (
          <div className="flex items-center space-x-4">
            <span className="text-white font-semibold">{userData?.name}</span>
            <button
              onClick={handleSignOut}
              className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg transition duration-300"
            >
              Sign Out
            </button>
          </div>
        )}
      </header>

      {/* Banner */}
      <div className="banner bg-gray-800 text-white text-center p-4">
        <h1 className="text-3xl font-semibold">
          <span className="featured text-green-500 font-bold px-1 py-1 rounded-md">
            Welcome {userData.name}
          </span>{" "}
          to{" "}
          <span className="featured text-orange-500 font-bold px-1 py-1 rounded-md">
            AYS Lite
          </span>
        </h1>
        <p className="text-lg mt-4">
          Find and book a service that suits your needs, we're{" "}
          <span className="text-yellow-400 italic font-semibold px-1 py-1">
            At Your Service!
          </span>
        </p>
      </div>

      {/* Category Pills */}
      <div className="category-pills flex overflow-x-auto space-x-4 p-4 bg-gray-800">
        <button
          onClick={() => setSelectedCategory(null)}
          className={`pill ${
            !selectedCategory ? "bg-orange-500" : "bg-gray-700"
          } text-white px-4 py-2 rounded-full`}
        >
          All Services
        </button>
        {categories.map((category) => (
          <button
            key={category.id}
            onClick={() => setSelectedCategory(category)}
            className={`pill ${
              selectedCategory === category ? "bg-orange-500" : "bg-gray-700"
            } text-white px-4 py-2 rounded-full`}
          >
            {category.name}
          </button>
        ))}
      </div>

      {/* Service Cards */}
      <div className="service-list grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 p-4">
        {filteredServices.map((service) => {
          const category = categories.find(
            (cat) => cat.id === service.category
          );
          return (
            <ServiceCard
              key={service.id}
              service={service}
              categoryName={category?.name || "Unknown Category"} // Passing category name
            />
          );
        })}
      </div>
    </div>
  );
};

export default Home;
