import React from 'react';

const CategoryList = ({ categories, onEditCategory }) => {
  // Function to get the parent category name based on the parentCategoryId
  const getParentCategoryName = (parentCategoryId) => {
    const parentCategory = categories.find(category => category.id === parentCategoryId);
    return parentCategory ? parentCategory.name : 'None'; // If parent exists, return the name; otherwise, 'None'
  };

  return (
    <div>
      <table className="min-w-full table-auto">
        <thead className="bg-gray-800">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase">Name</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase">Active</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase">Commission Based</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase">Parent Category</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase">Actions</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category) => (
            <tr key={category.id}>
              <td className="px-4 py-2">{category.name}</td>
              <td className="px-6 py-4">{category.active ? 'Yes' : 'No'}</td>
              <td className="px-6 py-4">{category.commissionBased ? 'Yes' : 'No'}</td>

              {/* Use the helper function to display the parent category name */}
              <td className="px-6 py-4">
                {category.isSubcategory ? getParentCategoryName(category.parentCategoryId) : 'None'}
              </td>

              <td className="border px-4 py-2">
                <button
                  onClick={() => onEditCategory(category)}
                  className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition duration-300"
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CategoryList;


















// import React from 'react';

// const CategoryList = ({ categories, onEditCategory }) => {
//   return (
//     <div>
//       <table className="min-w-full table-auto">
//         <thead className="bg-gray-800">
//           <tr>
//             <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase">Name</th>
//             <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase">Active</th>
//             <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase">Commission Based</th>
//             <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase">Parent Category</th>
//             <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase">Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {categories.map((category) => (
//             <tr key={category.id}>
//               <td className="px-4 py-2">{category.name}</td>
//               <td className="px-6 py-4">{category.active ? 'Yes' : 'No'}</td>
//               <td className="px-6 py-4">{category.commissionBased ? 'Yes' : 'No'}</td>
              
//               {/* Check if the category has a parent category and display its name */}
//               <td className="px-6 py-4">
//                 {category.parentCategory ? category.parentCategory.name : 'None'}
//               </td>

//               <td className="px-4 py-2">
//                 <button
//                   onClick={() => onEditCategory(category)}
//                   className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition duration-300"
//                 >
//                   Edit
//                 </button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default CategoryList;









// import React from 'react';

// const CategoryList = ({ categories, onEditCategory }) => {
//   return (
//     <div>
//       <table className="min-w-full table-auto">
     
//               <thead className="bg-gray-800">
//        <tr>
//          <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase">Name</th>
//          <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase">Active</th>
//          <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase">Actions</th>       </tr>
//        </thead>
//         <tbody>
//           {categories.map((category) => (
//             <tr key={category.id}>
//               <td className="border px-4 py-2">{category.name}</td>
//               <td className="px-6 py-4">{category.active ? 'Yes' : 'No'}</td>
//               <td className="border px-4 py-2">
//                 <button
//                   onClick={() => onEditCategory(category)}
//                   className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition duration-300"
//                 >
//                   Edit
//                 </button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default CategoryList;
