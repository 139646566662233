import { useQuery, useMutation, useQueryClient } from 'react-query';

import { collection, getDocs, addDoc, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../services/firebase.config';

// Fetch Services
const fetchServices = async () => {
  const servicesCollection = collection(db, 'AYS Lite Services');
  const serviceSnapshot = await getDocs(servicesCollection);
  const serviceList = serviceSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  return serviceList;
};

// Create Service
const addService = async (newService) => {
  const servicesCollection = collection(db, 'AYS Lite Services');
  await addDoc(servicesCollection, newService);
};

// Update Service
const editService = async (updatedService) => {
  const serviceRef = doc(db, 'AYS Lite Services', updatedService.id);
  await updateDoc(serviceRef, updatedService);
};

// Delete Service
const removeService = async (id) => {
  const serviceRef = doc(db, 'AYS Lite Services', id);
  await deleteDoc(serviceRef);
};

export const useServiceData = () => {
  const queryClient = useQueryClient();

  const servicesQuery = useQuery('AYS Lite Services', fetchServices);

  const createService = useMutation(addService, {
    onSuccess: () => {
      queryClient.invalidateQueries('AYS Lite Services');
    },
  });

  const updateService = useMutation(editService, {
    onSuccess: () => {
      queryClient.invalidateQueries('AYS Lite Services');
    },
  });

  const deleteService = useMutation(removeService, {
    onSuccess: () => {
      queryClient.invalidateQueries('AYS Lite Services');
    },
  });

  return {
    ...servicesQuery,
    createService: createService.mutate,
    updateService: updateService.mutate,
    deleteService: deleteService.mutate,
  };
};
