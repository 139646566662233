import React, { useState } from 'react';
import MainSpinner from '../../components/MainSpinner';
import ServiceProviderForm from '../../components/ServiceProviders/SPForm';
import SPList from '../../components/ServiceProviders/SPList';
import { useServiceProviderCrud } from '../../hooks/useServiceProdiverCrud';

const ServiceProviders = () => {
  const [showForm, setShowForm] = useState(false);
  const [selectedSProvider, setSelectedSProvider] = useState(null); // Track employee being edited
  const { serviceProviders=[], deleteServiceProvider, isLoading, isError } = useServiceProviderCrud();
  if (isLoading) return <MainSpinner/>;
  if (isError) return <p>Failed to load Service Providers..Check your connection.</p>;
  const toggleForm = () => {
    setShowForm(!showForm);
    setSelectedSProvider(null); // Reset selected employee when closing the form
  };

  const handleEdit = (sp) => {
    setSelectedSProvider(sp); // Set the selected sp for editing
    setShowForm(true); // Show the form with the selected employee data
  };

  const handleDelete = async (employeeId) => {
    // Delete logic here (e.g., call to backend to delete employee)
    console.log(`Delete Provider with ID: ${employeeId}`);
    try {
      await deleteServiceProvider(employeeId);
      console.log("Service Provider Deleted Successfully");
    } catch (error) {
      console.log(`Service Provider Deleting Employee: ${error}`);
    }
  };

  return (
    <div>
      <h2 className="text-3xl font-bold mb-4">Service Providers</h2>

      {!showForm && (
        <div className="mb-4">
          <button
            onClick={toggleForm}
            className="bg-orange-500 text-white py-2 px-4 rounded-md hover:bg-orange-600 transition duration-300"
          >
            Create SP
          </button>
        </div>
      )}

      {showForm ? (
        <ServiceProviderForm         
         serviceProvider={selectedSProvider} // Pass selected employee for editing
          closeForm={toggleForm}
        />
      ) : (
        <SPList
        serviceProviders={serviceProviders}
          onEdit={handleEdit} // Pass the edit handler to the list
          onDelete={handleDelete} // Pass the delete handler to the list
        />
      )}
    </div>
  );
};

export default ServiceProviders;









// import React, { useState } from 'react';
// import EmployeeList from '../Employees/EmployeeList';
// import EmployeeForm from '../Employees/EmployeeForm';

// const Employees = () => {
//   // State to toggle between the employee list and the form
//   const [showForm, setShowForm] = useState(false);

//   // Function to handle showing and hiding the form
//   const toggleForm = () => {
//     setShowForm(!showForm);
//   };

//   return (
//     <div>
//       <h2 className="text-3xl font-bold mb-4">Employees</h2>

//       {/* Show the create employee button if the form is not visible */}
//       {!showForm && (
//         <div className="mb-4">
//           <button
//             onClick={toggleForm}
//             className="bg-orange-500 text-white py-2 px-4 rounded-md hover:bg-orange-600 transition duration-300"
//           >
//             Create Employee
//           </button>
//         </div>
//       )}

//       {/* Conditionally render EmployeeForm or EmployeeList based on the showForm state */}
//       {showForm ? (
//         <div className="div">
//         <button
//             onClick={toggleForm}
//             className="bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600 transition duration-300"
//           >
//             Cancel
//           </button>
//         <EmployeeForm
//           closeForm={toggleForm} // Pass the toggleForm to the EmployeeForm so it can close when submitted
//         />
//          </div>
//       ) : (
      
//           <EmployeeList />
     
        
//       )}
//     </div>
//   );
// };

// export default Employees;

