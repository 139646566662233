import { useQuery, useMutation, useQueryClient } from 'react-query';
import { collection, doc, getDocs, getDoc, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../services/firebase.config';

// Fetch all affiliates
const fetchAffiliates = async () => {
  const affiliatesQuery = query(
    collection(db, 'AYS Lite Users'),
    where('role', '==', 'affiliate')
  );
  const snapshot = await getDocs(affiliatesQuery);
  return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

// Fetch affiliate by ID
const getAffiliateById = async (affiliateId) => {
  console.log(affiliateId);
  
  const affiliateRef = doc(db, 'AYS Lite Users', affiliateId);
  const affiliateSnap = await getDoc(affiliateRef);
  if (affiliateSnap.exists()) {
    return { id: affiliateSnap.id, ...affiliateSnap.data() };
  } else {
    throw new Error("Affiliate not found");
  }
};

// useAffiliateCrud hook
export const useAffiliateCrud = () => {
  const queryClient = useQueryClient();

  const { data: affiliates, isLoading, error } = useQuery('affiliates', fetchAffiliates);

  // Verify affiliate
  const verifyAffiliate = useMutation(
    async (affiliateId) => {
      const affiliateRef = doc(db, 'AYS Lite Users', affiliateId);
      await updateDoc(affiliateRef, { verificationStatus: 'verified', verified: true });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('affiliates');
      },
    }
  );

  return {
    affiliates,
    isLoading,
    error,
    verifyAffiliate,
    getAffiliateById,  // Exported function for affiliate details
  };
};














// // src/hooks/useAffiliateCrud.js
// import { useQuery, useMutation, useQueryClient } from 'react-query';
// import { collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
// import { db } from '../services/firebase.config';

// // Fetch all affiliates
// const fetchAffiliates = async () => {
//   const affiliatesQuery = query(
//     collection(db, 'AYS Lite Users'),
//     where('role', '==', 'affiliate')
//   );
//   const snapshot = await getDocs(affiliatesQuery);
//   return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
// };

// // useAffiliateCrud hook
// export const useAffiliateCrud = () => {
//   const queryClient = useQueryClient();

//   const { data: affiliates, isLoading, error } = useQuery('affiliates', fetchAffiliates);

//   // Verify affiliate
//   const verifyAffiliate = useMutation(
//     async (affiliateId) => {
//       const affiliateRef = doc(db, 'AYS Lite Users', affiliateId);
//       await updateDoc(affiliateRef, { verificationStatus: 'verified', verified: true });
//     },
//     {
//       onSuccess: () => {
//         queryClient.invalidateQueries('affiliates');
//       },
//     }
//   );

//   return {
//     affiliates,
//     isLoading,
//     error,
//     verifyAffiliate,
//   };
// };
