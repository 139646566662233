import { useState, useEffect } from 'react';
import { collection, addDoc, updateDoc, deleteDoc, doc, getDocs } from 'firebase/firestore';
import { db } from '../services/firebase.config';


export const useServiceProviderCrud = () => {
  const [serviceProviders, setServiceProviders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const fetchServiceProviders = async () => {
    setIsLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, 'AYS Employees'));
      const serviceProviderList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setServiceProviders(serviceProviderList);
      setIsLoading(false);
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
    }
  };

  const createServiceProvider = async (serviceProviderData) => {
    try {
      await addDoc(collection(db, 'AYS Employees'), serviceProviderData);
      fetchServiceProviders();
    } catch (error) {
      setIsError(true);
    }
  };

  const updateServiceProvider = async (id, updatedData) => {
    try {
      const ServiceProviderRef = doc(db, 'AYS Employees', id);
      await updateDoc(ServiceProviderRef, updatedData);
      fetchServiceProviders();
    } catch (error) {
      setIsError(true);
    }
  };

  const deleteServiceProvider = async (id) => {
    try {
      const ServiceProviderRef = doc(db, 'AYS Employees', id);
      await deleteDoc(ServiceProviderRef);
      fetchServiceProviders();
    } catch (error) {
      setIsError(true);
    }
  };

  useEffect(() => {
    fetchServiceProviders();
  }, []);

  return { serviceProviders, createServiceProvider, updateServiceProvider, deleteServiceProvider, isLoading, isError };
};
