// // src/components/AffiliateList.js
// src/components/Affiliates/AffiliateList.js

import React from 'react';
import { useAffiliateCrud } from '../../hooks/useAffiliateCrud';
import { Link } from 'react-router-dom';

const AffiliateList = ({ affiliates, onSelectAffiliate }) => {
    const {  isLoading, error, verifyAffiliate } = useAffiliateCrud();
  return (
    <div className="affiliate-list">
      <h3 className="text-xl font-semibold mb-2">Affiliates</h3>
      <ul>
        {affiliates.map(affiliate => (
          <li
            key={affiliate.id}
            className="bg-gray-700 p-3 rounded mb-2 cursor-pointer"
            
          >
             <p><strong>Name:</strong> {affiliate.name}</p>
            <p><strong>Email:</strong> {affiliate.email}</p>
             <p><strong>Status:</strong> {affiliate.verificationStatus || 'Unverified'}</p>
             
             <div className='flex'>
            
               <Link to={`/admin/affiliate-dashboard/${affiliate.id}`} className="mx-4"><button
               className="bg-green-500 text-white px-4 py-2 rounded mt-2 mr-2"
              
             >
              View
            </button></Link>
            <button
               className="bg-blue-500 text-white px-4 py-2 rounded mt-2 mr-2"
               onClick={() => onSelectAffiliate(affiliate)} // Set selected affiliate
             >
               Create Promo Code             </button>
             {affiliate.verified !==true && (<button
               className="bg-blue-500 text-white px-4 py-2 rounded mt-2"
               onClick={() => verifyAffiliate.mutate(affiliate.id)}
             >
               Verify
            </button>)}
             </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AffiliateList;







// import React from 'react';
// import { useAffiliateCrud } from '../../hooks/useAffiliateCrud';
// import MainSpinner from '../MainSpinner';


// const AffiliateList = () => {
//   const { affiliates, isLoading, error, verifyAffiliate } = useAffiliateCrud();

//   if (isLoading) return <MainSpinner />;
//   if (error) return <p>Error loading affiliates: {error.message}</p>;

//   return (
//     <div>
//       <h2 className="text-2xl font-bold mb-4">Affiliates</h2>
//       <ul>
//         {affiliates.map((affiliate) => (
//           <li key={affiliate.id} className="bg-gray-800 p-4 mb-4 rounded-lg shadow-lg">
//             <p><strong>Name:</strong> {affiliate.name}</p>
//             <p><strong>Email:</strong> {affiliate.email}</p>
//             <p><strong>Status:</strong> {affiliate.verificationStatus || 'Unverified'}</p>
//             <button
//               className="bg-blue-500 text-white px-4 py-2 rounded mt-2"
//               onClick={() => verifyAffiliate.mutate(affiliate.id)}
//             >
//               Verify
//             </button>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default AffiliateList;
