import { useQuery, useMutation, useQueryClient } from 'react-query';
import { db } from '../services/firebase.config';
import { collection, addDoc, getDocs, updateDoc, doc, deleteDoc } from 'firebase/firestore';

const citiesCollectionRef = collection(db, 'Cities');

export const useCityData = () => {
  const queryClient = useQueryClient();

  // Fetch Cities
  const { data: cities = [], isLoading, isError } = useQuery('Cities', async () => {
    const querySnapshot = await getDocs(citiesCollectionRef);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  });

  // Create City
  const createCity = useMutation(async (newCity) => {
    await addDoc(citiesCollectionRef, newCity);
  }, {
    onSuccess: () => queryClient.invalidateQueries('Cities'),
  });

  // Update City
  const updateCity = useMutation(async ({ id, updatedCity }) => {
    const cityDoc = doc(db, 'Cities', id);
    await updateDoc(cityDoc, updatedCity);
  }, {
    onSuccess: () => queryClient.invalidateQueries('Cities'),
  });

  // Delete City
  const deleteCity = useMutation(async (id) => {
    const cityDoc = doc(db, 'Cities', id);
    await deleteDoc(cityDoc);
  }, {
    onSuccess: () => queryClient.invalidateQueries('Cities'),
  });

  return {
    cities,
    isLoading,
    isError,
    createCity: createCity.mutate,
    updateCity: updateCity.mutate,
    deleteCity: deleteCity.mutate,
  };
};
