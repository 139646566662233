import React from 'react';
import { useServiceData } from '../../hooks/useServices';
import ServiceTableRow from './ServiceTableRow';
import MainSpinner from '../MainSpinner';

const ServiceList = ({ onEdit }) => {
  const { data: services, isLoading, error } = useServiceData();

  if (isLoading) return <MainSpinner/>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <table className="min-w-full bg-gray-800 text-white rounded-lg shadow-md">
      <thead>
        <tr>
          <th className="px-4 py-2">Service Name</th>
          <th className="px-4 py-2">Category</th>
          <th className="px-4 py-2">Price Range</th>
          <th className="px-4 py-2">Actions</th>
        </tr>
      </thead>
      <tbody>
        {services.map(service => (
          <ServiceTableRow 
            key={service.id} 
            service={service} 
            onEdit={onEdit}
          />
        ))}
      </tbody>
    </table>
  );
};

export default ServiceList;
