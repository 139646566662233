// src/hooks/useCommissionTracker.js
import react from 'react';
import { useQuery } from 'react-query';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../services/firebase.config';

// Fetch commissions for an affiliate
const fetchAffiliateCommissions = async (affiliateId) => {
  const commissionsQuery = query(
    collection(db, 'Affiliate Commissions'),
    where('affiliateId', '==', affiliateId)
  );
  const snapshot = await getDocs(commissionsQuery);
  return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

// useCommissionTracker hook
export const useCommissionTracker = (affiliateId) => {
  const { data: commissions, isLoading, error } = useQuery(
    ['commissions', affiliateId],
    () => fetchAffiliateCommissions(affiliateId),
    { enabled: !!affiliateId }
  );

  return { commissions, isLoading, error };
};
