import React from 'react';
import { useCityData } from '../../hooks/useCityData';

const CityList = ({ onEditCity }) => {
  const { cities, isLoading, isError, deleteCity } = useCityData();

  if (isLoading) return <p>Loading cities...</p>;
  if (isError) return <p>Error loading cities</p>;

  const handleDelete = (id) => {
    deleteCity(id);
  };

  return (
    <table className="min-w-full table-auto">
      <thead className="bg-gray-800">
        <tr>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase">Name</th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase">Active</th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase">Actions</th>
        </tr>
      </thead>
      <tbody>
        {cities.map((city) => (
          <tr key={city.id} className="hover:bg-gray-700">
            <td className="border px-4 py-2">{city.name}</td>
            <td className="px-6 py-4">{city.active ? 'Yes' : 'No'}</td>
            <td className="border px-4 py-2">
              <button
                onClick={() => onEditCity(city)}
                className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition duration-300"
              >
                Edit
              </button>
              <button
                onClick={() => handleDelete(city.id)}
                className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition duration-300 ml-2"
              >
                Delete
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CityList;
