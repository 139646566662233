import React, { useState } from 'react';
import { Link, useLocation, useNavigate  } from 'react-router-dom';
import { useAuthData } from '../../context/AuthContext';
import { signOut } from 'firebase/auth';
import { FaChevronRight, FaChevronLeft, FaUserCircle, FaSignOutAlt, FaCog } from 'react-icons/fa';
import { auth } from '../../services/firebase.config';


const SideMenu = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { pathname } = useLocation();
  const { userData} = useAuthData();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signOut(auth); // Sign out from Firebase
      navigate('/login');  // Redirect to login page
    } catch (error) {
      console.error('Sign out failed', error);
    }
  };
  const toggleMenu = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`flex flex-col h-screen bg-gray-800 text-white ${isExpanded ? 'w-64' : 'w-20'} transition-width duration-300`}>
      {/* Menu Toggle Button */}
      <div className="flex justify-between items-center p-4 bg-gray-900">
        <h1 className={`text-lg font-bold transition-opacity ${isExpanded ? 'opacity-100' : 'opacity-0 hidden'}`}>AYS Admin</h1>
        <button onClick={toggleMenu} className="text-white focus:outline-none">
          {isExpanded ? <FaChevronLeft /> : <FaChevronRight />}
        </button>
      </div>

      {/* Menu Items */}
      <div className="flex-1 overflow-y-auto">
        <MenuItem to="/admin/customers" title="Customers" isExpanded={isExpanded} icon={<FaUserCircle />} active={pathname === '/admin/customers'} />
        <MenuItem to="/admin/service-providers" title="Service Providers" isExpanded={isExpanded} icon={<FaUserCircle />} active={pathname === '/admin/employees'} />
        <MenuItem to="/admin/services" title="Services" isExpanded={isExpanded} icon={<FaUserCircle />} active={pathname === '/admin/services'} />
        <MenuItem to="/admin/requests" title="Requests" isExpanded={isExpanded} icon={<FaUserCircle />} active={pathname === '/admin/requests'} />
        <MenuItem to="/admin/affiliate-dashboard" title="Affiliates" isExpanded={isExpanded} icon={<FaUserCircle />} active={pathname === '/admin/requests'} />
        <MenuItem to="/admin/bookings" title="Bookings" isExpanded={isExpanded} icon={<FaUserCircle />} active={pathname === '/admin/bookings'} />
        <MenuItem to="/admin/categories" title="Categories" isExpanded={isExpanded} icon={<FaUserCircle />} active={pathname === '/admin/categories'} />
        <MenuItem to="/admin/locations" title="Locations" isExpanded={isExpanded} icon={<FaUserCircle />} active={pathname === '/admin/locations'} />
      </div>

      {/* User Info & Settings */}
      <div className="p-4 border-t border-gray-700">
        <div className="flex items-center">
          <FaUserCircle size={28} />
          <div className={`ml-4 ${isExpanded ? 'block' : 'hidden'}`}>
            <p>{userData?.email}</p>
            <p className="text-sm text-gray-400">{userData?.role || 'Admin'}</p>
          </div>
        </div>
        <div className={`mt-4 flex justify-between items-center ${isExpanded ? 'block' : 'hidden'}`}>
          <button
            onClick={handleSignOut}
            className="flex items-center w-full text-red-500 hover:text-red-600 transition duration-300"
          >
            <FaSignOutAlt className="mr-2" /> Sign Out
          </button>
          <Link to="/admin/settings" className="text-gray-500 hover:text-gray-400">
            <FaCog />
          </Link>
        </div>
      </div>
    </div>
  );
};

const MenuItem = ({ to, title, icon, isExpanded, active }) => (
  <Link to={to} className={`block p-4 ${active ? 'bg-gray-700' : 'hover:bg-gray-700'} transition duration-300`}>
    <div className="flex items-center">
      {icon}
      <span className={`ml-4 transition-opacity ${isExpanded ? 'opacity-100' : 'opacity-0 hidden'}`}>{title}</span>
    </div>
  </Link>
);

export default SideMenu;
