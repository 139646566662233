// src/hooks/useAuth.js
import { useMutation } from 'react-query';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../services/firebase.config';
// import { auth } from '../services/firebaseConfig';

export const useAuth = () => {
  // Admin login mutation
  const loginMutation = useMutation(
    ({ email, password }) => signInWithEmailAndPassword(auth, email, password)
  );

  // Employee signup mutation
  const signupMutation = useMutation(
    ({ email, password }) => createUserWithEmailAndPassword(auth, email, password)
  );

  return { loginMutation, signupMutation };
};
